import React,{ useState } from 'react'
import VendorDataList from './vendorRegistration/DataList'
import { Navigate } from "react-router-dom";
import Menu from './Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'
import EditEmpanelmentForm from './vendorRegistration/EditEmpanelmentForm'
import QrCodeGenerator from './qrcodegen';
// import axios from 'axios'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#161F29',
      // main: '#1976d2',
    },
  },
});

function Layout(props) {
  const [imgUri, setImgUri] = useState("")
  const navigate = useNavigate();
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('USER'))
  }catch(err){
    authToken = null
  }
  
  console.log(authToken)
  if (!authToken){
    return <Navigate to="/auth" replace />;
  }

  const handleLogout = () => {
    localStorage.setItem('USER',null)
    navigate("/auth");
  }
  const handleInputChange = async(event) => {
    var blob = new Blob(event.target.files, {type: "image/png"})
    // const res = await fetch("https://s3.ap-south-1.amazonaws.com/forms.company/forms_docs/booking_amenities.jpeg")
    // const blob = await res.buffer()
    let url = URL.createObjectURL(blob)
    setImgUri(url)
  }

  

  return (
    <div style={{display:"flex",flexDirection:"row",width:"100%",marginTop:"4rem"}}>
      <ThemeProvider theme={darkTheme}>
        <AppBar color="primary" style={{position:"fixed",height:"4rem"}}>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Dashboard
            </Typography>
            <Button onClick={handleLogout} color="inherit">Logout</Button>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      <div style={{width:"25%",background:"#304665",height:"100%", minHeight:"94vh", color:"white",maxWidth:"250px",position:'fixed'}}>
        <Menu />
      </div>
      <div style={{width:"25%",background:"#304665",height:"100%", minHeight:"94vh", color:"white",maxWidth:"250px"}}></div>
      <div style={{width:'80%',height:"100%", overflow:"scroll" }}>
        <Routes>
          {/* <Route exact path='/' element={<VendorDataList/>}/> */}
          <Route exact path='/vendor' element={<VendorDataList/>}/>
          <Route exact path='/vendor/edit/:id' element={<EditEmpanelmentForm />}/>
          <Route exact path='/qrcodegen' element={<QrCodeGenerator />}/>
        </Routes>
      </div>     
    </div>
  )
}

export default Layout

