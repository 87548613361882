import React from 'react'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import HandshakeIcon from '@mui/icons-material/Handshake';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { BrowserRouter , Route, Routes, Switch, Link, Redirect } from 'react-router-dom'

function Menu() {
  const [menus, setMenus] = React.useState([
    {
      name: "Channel Partners",
      subItems: [],
      open: false,
      url: 'vendor',
      icon: <HandshakeIcon sx={{color:"white",margin:"0.5rem 0.7rem 0.5rem 0rem"}}/>
    },
    {
      name: "QR Code Generator",
      subItems: [],
      open: false,
      url: 'qrcodegen',
      icon: <QrCodeIcon sx={{color:"white",margin:"0.5rem 0.7rem 0.5rem 0rem"}}/>
    }
  ]);

  // const handleClick = () => {
  //   setOpen(!open);
  // };


  return (
    <List
      sx={{ width: '100%', maxWidth: 360,  }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {menus.map(item => (
        <Link key={item.url} to={item.url} style={{all:'unset'}}>
          <ListItemButton  >
            {/* <ListItemIcon sx={{width:"15px"}}> */}
              {item.icon}
            {/* </ListItemIcon> */}
            <ListItemText primary={item.name} />
            {
              item.subItems.length > 0 && (item.open ? <ExpandLess /> : <ExpandMore />)
            }
          </ListItemButton>
          {item.subItems.map(ele => (
            <Collapse in={item.open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <StarBorder />
                  </ListItemIcon>
                  <ListItemText primary={ele?.name} />
                </ListItemButton>
              </List>
            </Collapse>
          ))}
        </Link>
      ) )}
    </List>
  )
}

export default Menu