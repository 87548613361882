export const getBookingData = async(bookingId) => {
    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    let response = await fetch(`https://feedback-app.hoabl.in/api/crm/booking/${bookingId}`, requestOptions);
    let bookingData = {};
    if(response.status === 200){
        let data = await response.json();
        bookingData = data.response; 
    }
    return bookingData;
};