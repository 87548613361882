import axios from 'axios'

export const sharePointAuth = async() => {
  var requestOptions = {
    method: 'POST',
    redirect: 'follow'
  };
  
  fetch("https://feedback-app.hoabl.in/api/sharepoint/token", requestOptions)
    .then(response => response.json())
    .then(result => {
      localStorage.setItem('SP_A',JSON.stringify(result.token))
      // console.log(result.token)
    })
    .catch(error => console.log('error', error));

}

export const handleSharepointFileUpload = (file, filename, params) => {
  if(!filename) return;
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('SP_A'))
  }catch(err){
    authToken = null
  }
  // console.log(authToken)
  let genUrl = `https://hoabl.sharepoint.com/HOABL%20CRM/_api/web/GetFolderByServerRelativeUrl('/HOABL%20CRM/contact/${params.full_name}_${params.contactId}/quote/${params.sharepoint_folder_name}_${params.bookingId}/Unverified Documents')/Files/add(url='${filename}',overwrite=true)`
  var myHeaders = new Headers();  
  myHeaders.append("Authorization", `Bearer ${authToken}`);
  myHeaders.append("Accept", "application/json");
  myHeaders.append("X-RequestDigest", "0xF79F2ECD2958BD437DF4189430039F954F3A48C268D3E8D7F53318B998BD10D79D3134DE47C0E9C650960CDC13FF545998DF28F5621F7BDE8D11AD102C1118A7,03 Jun 2022 11:25:58 -0000");
  myHeaders.append("Content-Type", "application/json;odata=verbose");
  myHeaders.append("If-Match", "*");

  var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: file,
  redirect: 'follow'
  };
  // fetch(`https://hoabl.sharepoint.com/HOABL%20CRM/_api/web/GetFolderByServerRelativeUrl('/HOABL%20CRM/contact/Rutvik test Pimple_969b4c55b440ed11bba2002248d5d208/quote/Rutvik test Pimple_test 0001_2285516502644079a46509871e1afccf/Unverified Documents')/Files/add(url='${filename}',overwrite=true)`, requestOptions)
  fetch(genUrl, requestOptions)
  .then(response => response.text())
  .then(result => console.log("result"))
  .catch(error => console.log('error', error));
}


// user.crmId.replace(/-/g, "").toUpperCase()