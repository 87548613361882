import React from 'react'
import {useNavigate} from "react-router-dom"

function LeadForm() {
  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(e.target.fullname.value,e.target.email.value,e.target.phone.value)

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let payload = JSON.stringify({
      "firstname": e.target.fullname.value?.split(" ")[0],
      "lastname": e.target.fullname.value?.split(" ")[1],
      "mobilephone": e.target.phone.value,
      "emailaddress1": e.target.email.value,
      "hoabl_leadsource": 225360015,
      "hoabl_leadenquirystatus": 225360000,
      "hoabl_ProjectInterestedIn@odata.bind": "/hoabl_projects(cbfcdb12-b9f6-ec11-82e5-000d3aca33aa)",
      "hoabl_Launch@odata.bind": "/hoabl_launchphases(326d91e1-39f8-ec11-82e4-000d3aca38cf)",
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: payload,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/api/crm/create-lead", requestOptions)
      .then(result => {
        if (result.status === 400){
          navigate("/error")
          return 
        }
        console.log(result)
        navigate("/lead-demo/submited")
      })
      .catch(error => {
        navigate("/error")
        console.log('error', error)
      });

  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        textAlign: "center",
        alignItems: "center",
        marginTop: "5rem",
        background: "whitesmoke",
        padding: "5rem",
        boxShadow:'10px 5px 5px grey'
      }}
    >
      <h2>Lead Form</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ margin: "0.2rem 0 0 0", textAlign: "left" }}>
          <label>Project:</label>
          <br />
          <select
            style={{ width: "20rem" }}
            name="project"
            placeholder="Projects"
          >
            <option value="Ayodhya">Foothills Of Matheran</option>
          </select>
        </div>
        <br />
        <div style={{ margin: "0.2rem 0 0 0", textAlign: "left" }}>
          <label>Full Name:</label>
          <br />
          <input style={{ width: "20rem" }} type="text" name="fullname"></input>
          <br />
        </div>
        <div style={{ margin: "0.2rem 0 0 0", textAlign: "left" }}>
          <label>Phone:</label>
          <br />
          <input style={{ width: "20rem" }} type="phone" name="phone"></input>
          <br />
        </div>
        <div style={{ margin: "0.2rem 0 0 0", textAlign: "left" }}>
          <label>Email:</label>
          <br />
          <input style={{ width: "20rem" }} type="email" name="email"></input>
          <br />
        </div>

        <button style={{ margin: "1rem 0", width: "10rem" }} type="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default LeadForm