import React,{ useEffect } from 'react'
import hoabl_logo from "../../assets/the house of abhinandan lodha logo-02.png"


function Error() {

  return (
    <div style={{ padding: "1rem", width:"80%",minWidth:"360px"}}>
      <div style={{background:"#161617", width:"100%", padding:"1rem", marginBottom:"1rem", color:"white", borderRadius:"5px", alignContent:"center", alignItems:"center",textAlign:"center"}}>
        <img src={hoabl_logo} width="150px" />
        <h2 style={{color:"#FFFFFF", fontFamily:"Jost"}}>Error!!!</h2>
        <p style={{color:"#FFFFFF", fontFamily:"Jost"}}>Something Went Wrong, Please contact administrator </p>
      </div>
    </div>
  )
}

export default Error