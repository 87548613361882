import React,{ useEffect } from 'react'
import hoabl_logo from "../../assets/the house of abhinandan lodha logo-02.png"
import Timeline from "./Timeline"
import {useNavigate} from "react-router-dom"

function Thanks() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "https://www.hoabl.in/partners/#/login"
    },20000)
  
  }, [])
  

  return (
    <div style={{ padding: "1rem", width:"80%",minWidth:"360px"}}>
      <div style={{background:"#161617", width:"100%", padding:"1rem", marginBottom:"1rem", color:"white", borderRadius:"5px", alignContent:"center", alignItems:"center",textAlign:"center"}}>
        <img src={hoabl_logo} width="150px" />
        <h2 style={{color:"#FFFFFF", fontFamily:"Jost"}}>Thank You!!!</h2>
        <p style={{color:"#FFFFFF", fontFamily:"Jost"}}>Your registration is under verification, we will get back to you shortly.</p>
      </div>
      <Timeline />
      <a style={{all:"unset"}} href="https://www.hoabl.in/partners/#/login">
      <button >
        Done
      </button>
      </a>
    </div>
  )
}

export default Thanks