// Step.js
import React, { useContext } from "react";
import { FormContext } from "./index";
import Basic from "./forms/Basic";
import SecondPage from "./forms/SecondPage";
import FinalPage from "./forms/FinalPage";

function Step({data}) {  
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <Basic data={data}/>;
      break;
    case 1:
      stepContent = <SecondPage data={data} />;
      break;
    case 2:
      stepContent = <FinalPage data={data}/>;
      break;
   
    default:
      break;
  }

  return stepContent;
}

export default Step;
