// Workspace.js
import { ErrorMessage, Field, Form, Formik, FieldArray } from "formik";
import React, { useContext, useState } from "react";
import { FormContext } from "../index";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { choices } from "./choices";
import MenuItem from "@mui/material/MenuItem";

const customerOnboarding2Schema = yup.object().shape({
  hoabl_whendidyougethitchedforlife: yup.string().when("marital_status", {
    is: "married",
    then: yup.string().required("Please add details"),
  }),
  hoabl_noofchildrenifapplicable: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_nameofyourchild1ifapplicable: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_whencanwewishyourchild: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_nameofyourchild2ifapplicable: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_whencanwewishyourchild2: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_purposeofinvestmentwithhoabl: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_whatcaughtyourattentionatthetimeofpurchase: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_postpossessionplans: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_howdoyouspendyourtimeoff: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_favoritesport: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_typeofholidaysyouenjoy: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_whatexcitesyoufromthelist: yup.string().max(100,"Max Characters cannot exceed 100"),
  // hoabl_whatareyourlongterminvestmentgoals: yup.string().required("Please add details"),
  hoabl_whattvchannelsdoyouwatch: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_wheredoyouwatchshowsmovieslistentomusic: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_whichnewspaperdoyouread: yup.string().max(100,"Max Characters cannot exceed 100"),
  marital_status: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_spouseprofession: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_spousedesignation: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_spousemobilenumber: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_spousedob: yup.string().when("marital_status", {
    is: "married",
    then: yup.string().required("Please add details"),
  }),
  hoabl_whatareyourholidayplanslike: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_whatareyourinterests: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_whatisyourpreferredformofentertainmentandi: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_whichisyourpreferredsocialmediachannel: yup.string().max(100,"Max Characters cannot exceed 100"),
  // hoabl_spouseprofessiondesignation: yup.string(),
});

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton-co" {...field} {...props}>
      {props.children}
    </button>
  );
};

function SecondPage() {
  const [isloading, setIsloading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const childrensGroup = {};

  const navigate = useNavigate();
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  const renderError = (message) => (
    <p className="italic text-red-600">{message}</p>
  );

  return (
    <div style={{background:"rgb(255,255,255", padding: "1rem", borderRadius:'10px' }}>
    <Formik
      initialValues={{
        childrens: [childrensGroup],
        hoabl_noofchildrenifapplicable: 0,
        ...formData,

      }}
      validationSchema={customerOnboarding2Schema}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setActiveStepIndex(activeStepIndex + 1);
      }}
    >
      {({ touched, errors, values, submitCount, setFieldValue }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              {/* <div style={{ padding: "1rem" }}> */}
              <h4 style={{ font: "Jost", fontWeight:'bold' }}>Tell Us More</h4>
              <p style={{ font: "Jost", color:'#161617', fontWeight:'400' }}>These details will help us curate a customized experience and make your journey smoother with HoABL.</p>
              {/* </div> */}
            </Grid>
            <Grid item md={3} xs={3}>
              <Field
                name="marital_status"
                label="Marital Status*"
                fullWidth
                select={true}
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  },
                }}
                onChange={(e) => {
                  setFieldValue("marital_status",e.target.value)
                }}
                component={TextInput}
              >
                <MenuItem key="1" value="single">Single</MenuItem>
                <MenuItem key="2" value="married">Married</MenuItem>
              </Field>
            </Grid>
            {values?.marital_status === "married" && (
              <Grid item md={3} xs={12}>
                <Field
                  name="hoabl_whendidyougethitchedforlife"
                  label="Marriage Anniversary"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  component={TextInput}
                />
              </Grid>
            )}
            {/* <Grid item md={3} xs={6}>
              <div style={{ padding: "0.2rem" }}>
                <p>Marital Status:</p>
              </div>
            </Grid>
            <Grid item md={3} xs={6}>
              <Field
                as="select"
                label="Marital Status"
                name="marital_status"
                className="select"
                style={
                  (touched?.marital_status && errors?.marital_status) ||
                  (errors?.marital_status && submitCount)
                    ? {
                        borderColor: "rgb(211,47,47)",
                        color: "rgb(211,47,47)",
                      }
                    : {}
                }
              >
                <option value="">Choose...</option>
                <option key="1" value="single">
                  Single
                </option>
                <option key="2" value="married">
                  Married
                </option>
              </Field>
              {touched?.marital_status && errors?.marital_status && (
                <p className="error">{errors?.marital_status}</p>
              )}
            </Grid> */}
            <Grid item md={9} xs={12}></Grid>
            {values?.marital_status === "married" && (
              <>
                <Grid item md={12} xs={12}>
                  {/* <div style={{ padding: "1rem" }}> */}
                  <h4 style={{ font: "Jost" }}>Family's Details</h4>
                  {/* <p style={{ font: "Jost", color:'#161617', fontWeight:'400' }}>These details will help us curate a customized experience and make your journey smoother with HoABL.</p> */}
                  {/* </div> */}
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="hoabl_spousenameifapplicable"
                    label="Spouse Name"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="hoabl_spousemobilenumber"
                    label="Spouse Mobile Number"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                {/* <Grid item md={3} xs={6}>
                  <div style={{ padding: "0.2rem" }}>
                    <p>Marriage Anniversary<span style={{color:"red"}}>*</span></p>
                  </div>
                </Grid> */}
                
                <Grid item md={3} xs={6}>
                  <div style={{ padding: "0.2rem" }}>
                    <p>Spouse Date of Birth:<span style={{color:"red"}}>*</span></p>
                  </div>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Field
                    name="hoabl_spousedob"
                    label=""
                    type="date"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="hoabl_spouseprofession"
                    label="Spouse Profession"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="hoabl_spousedesignation"
                    label="Spouse Designation"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="hoabl_spouseorganisation"
                    label="Spouse Organisation"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={3} xs={6}>
                  <div style={{ padding: "0.2rem" }}>
                    <p>No of children (if applicable):</p>
                  </div>
                </Grid>
                <Grid item md={3} xs={6}>
                  <Field
                    as="select"
                    label="No of children (if applicable)"
                    name="hoabl_noofchildrenifapplicable"
                    className="select"
                    onChange = {(e) => {
                      // console.log(e.target.value)
                      let arr = []
                      for(let i=0; i<e.target.value; i++){
                        arr.push({})
                      }
                      setFieldValue(
                        "childrens",
                        arr
                      )
                      setFieldValue(
                        "hoabl_noofchildrenifapplicable",
                        e.target.value
                      )
                      // console.log(values.childrens,values.hoabl_noofchildrenifapplicable)
                    }}
                    style={
                      (touched?.hoabl_noofchildrenifapplicable &&
                        errors?.hoabl_noofchildrenifapplicable) ||
                      (errors?.hoabl_noofchildrenifapplicable && submitCount)
                        ? {
                            borderColor: "rgb(211,47,47)",
                            color: "rgb(211,47,47)",
                          }
                        : {}
                    }
                  >
                    {/* <option value="">Choose...</option> */}
                    {choices.hoabl_noofchildrenifapplicable.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                  </Field>
                  {touched?.hoabl_noofchildrenifapplicable &&
                    errors?.hoabl_noofchildrenifapplicable && (
                      <p className="error">
                        {errors?.hoabl_noofchildrenifapplicable}
                      </p>
                    )}
                </Grid>
                <Grid item md={6} xs={0}></Grid>
                {values?.hoabl_noofchildrenifapplicable!==0 && (
                  <FieldArray name="childrens">
                    {({ push, remove }) => (
                      <Grid container spacing={2} sx={{ marginTop: 2, paddingX: 2 }}>
                        {values?.childrens?.map((_, index) => (
                          <>
                            <Grid key={`1${index}`} item md={6} xs={12}>
                              <Field
                                name={`childrens.${index}.hoabl_nameofyourchildifapplicable`}
                                label={`Name of your child ${index + 1}`}
                                fullWidth
                                component={TextInput}
                              />
                            </Grid>
                            <Grid key={`2${index}`} item md={3} xs={6}>
                              <div style={{ padding: "0.2rem" }}>
                                <p>Date of birth:</p>
                              </div>
                            </Grid>
                            <Grid key={`3${index}`} item md={3} xs={12}>
                              <Field
                                name={`childrens.${index}.hoabl_whencanwewishyourchild`}
                                label=""
                                type="date"
                                fullWidth
                                component={TextInput}
                              />
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    )}
                  </FieldArray>
                )}
                
              </>
            )}

            <Grid item md={12} xs={12}>
              {/* <div style={{ padding: "1rem" }}> */}
              <h4 style={{ font: "Jost" }}>Plan Your Post Possession Journey</h4>
              {/* </div> */}
            </Grid>

            <Grid item md={12} xs={12}>
              <Field
                name="hoabl_purposeofinvestmentwithhoabl"
                label="Purpose of investment with HoABL"
                required
                fullWidth
                select={true}
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  },
                }}
                onChange={(e) => {
                  setFieldValue("hoabl_purposeofinvestmentwithhoabl",e.target.value)
                }}
                component={TextInput}
              >
                {choices.hoabl_purposeofinvestmentwithhoabl.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
              </Field>
            </Grid>
            <Grid item md={12} xs={12}>
              <Field
                name="hoabl_whatcaughtyourattentionatthetimeofpurchase"
                label="What caught your attention at the time of purchase?"
                fullWidth
                select={true}
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  },
                }}
                onChange={(e) => {
                  setFieldValue("hoabl_whatcaughtyourattentionatthetimeofpurchase",e.target.value)
                }}
                component={TextInput}
              >
                {choices.hoabl_whatcaughtyourattentionatthetimeofpurchase.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
              </Field>
            </Grid>
            <Grid item md={12} xs={12}>
              <Field
                name="hoabl_postpossessionplans"
                label="Post possession plans"
                required
                fullWidth
                select={true}
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  },
                }}
                onChange={(e) => {
                  setFieldValue("hoabl_postpossessionplans",e.target.value)
                }}
                component={TextInput}
              >
                {choices.hoabl_postpossessionplans.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
              </Field>
            </Grid>

            <Grid item md={12} xs={12}>
              {/* <div style={{ padding: "1rem" }}> */}
              <h4 style={{ font: "Jost" }}>Your Interests</h4>
              {/* </div> */}
            </Grid>

            <Grid item md={12} xs={12}>
              <Field
                name="hoabl_whatareyourholidayplanslike"
                label="What are your holiday plans like?"
                fullWidth
                select={true}
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  },
                }}
                onChange={(e) => {
                  setFieldValue("hoabl_whatareyourholidayplanslike",e.target.value)
                }}
                component={TextInput}
              >
                {choices.hoabl_whatareyourholidayplanslike.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
              </Field>
            </Grid>

            <Grid item md={12} xs={12}>
              <Field
                name="hoabl_whatareyourinterests"
                label="What are your interests?"
                fullWidth
                select={true}
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  },
                }}
                onChange={(e) => {
                  setFieldValue("hoabl_whatareyourinterests",e.target.value)
                }}
                component={TextInput}
              >
                {choices.hoabl_whatareyourinterests.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
              </Field>
            </Grid>

            <Grid item md={12} xs={12}>
              <Field
                name="hoabl_whatisyourpreferredformofentertainmentandi"
                label="What is your preferred form of entertainment and information?"
                fullWidth
                select={true}
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  },
                }}
                onChange={(e) => {
                  setFieldValue("hoabl_whatisyourpreferredformofentertainmentandi",e.target.value)
                }}
                component={TextInput}
              >
                {choices.hoabl_whatisyourpreferredformofentertainmentandi.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
              </Field>
            </Grid>

            <Grid item md={12} xs={12}>
              <Field
                name="hoabl_whichisyourpreferredsocialmediachannel"
                label="Which is your preferred social media channel?"
                fullWidth
                select={true}
                SelectProps={{
                  onClose: () => {
                    setTimeout(() => {
                      document.activeElement.blur();
                    }, 0);
                  },
                }}
                onChange={(e) => {
                  setFieldValue("hoabl_whichisyourpreferredsocialmediachannel",e.target.value)
                }}
                component={TextInput}
              >
                {choices.hoabl_whichisyourpreferredsocialmediachannel.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
              </Field>
            </Grid>
            
            
            <Grid item md={6} xs={12}></Grid>
            <Grid item md={8} xs={4}>
              {submitClicked && errors && (
                <p className="error">Please check the form for validations<span style={{color:"red"}}>*</span></p>
              )}
            </Grid>
            <Grid item md={2} xs={4}>
              <Field
                name="back"
                component={CustomButton}
                onClick={() => setActiveStepIndex((prev) => prev - 1)}
              >
                <span style={{fontWeight:'bold'}} >BACK</span>
              </Field>
            </Grid>
            <Grid item md={2} xs={4}>
              <Field
                name="submit"
                type="submit"
                component={CustomButton}
                onClick={(e) => setSubmitClicked(true)}
              >
                {isloading ? (
                  <CircularProgress sx={{ color: "#FFFFFF" }} size="1.5rem" />
                ) : (
                  <span style={{fontWeight:'bold'}} >NEXT</span>
                )}
              </Field>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
    </div>
  );
}

export default SecondPage;







// {values?.hoabl_noofchildrenifapplicable &&
//   Array.from({
//     length: Number(values?.hoabl_noofchildrenifapplicable),
//   }).map((item, index) => (
//     <>
//       <Grid key={`1${index}`} item md={6} xs={12}>
//         <Field
//           name={`hoabl_nameofyourchild${index + 1}ifapplicable`}
//           label={`Name of your child ${index + 1}`}
//           fullWidth
//           component={TextInput}
//         />
//       </Grid>
//       <Grid key={`2${index}`} item md={3} xs={6}>
//         <div style={{ padding: "0.2rem" }}>
//           <p>When can we wish your child {index + 1}?</p>
//         </div>
//       </Grid>
//       <Grid key={`3${index}`} item md={3} xs={12}>
//         <Field
//           name={`hoabl_whencanwewishyourchild${index + 1}`}
//           label=""
//           type="date"
//           fullWidth
//           component={TextInput}
//         />
//       </Grid>
//     </>
//   ))}


// Array.from({
//       length: Number(values?.hoabl_noofchildrenifapplicable),
//     }).map((item, index) =>









// <Grid item md={3} xs={6}>
//               <div style={{ padding: "0.2rem" }}>
//                 <p>How do you spend your time off?</p>
//               </div>
//             </Grid>
//             <Grid item md={3} xs={6}>
//               <Field
//                 as="select"
//                 label="How do you spend your time off?"
//                 name="hoabl_howdoyouspendyourtimeoff"
//                 className="select"
//                 style={
//                   (touched?.hoabl_howdoyouspendyourtimeoff &&
//                     errors?.hoabl_howdoyouspendyourtimeoff) ||
//                   (errors?.hoabl_howdoyouspendyourtimeoff && submitCount)
//                     ? {
//                         borderColor: "rgb(211,47,47)",
//                         color: "rgb(211,47,47)",
//                       }
//                     : {}
//                 }
//               >
//                 <option value="">Choose...</option>
//                 {choices.hoabl_howdoyouspendyourtimeoff.map((item) => (
//                   <option key={item.id} value={item.value}>
//                     {item.value}
//                   </option>
//                 ))}
//               </Field>
//               {touched?.hoabl_howdoyouspendyourtimeoff &&
//                 errors?.hoabl_howdoyouspendyourtimeoff && (
//                   <p className="error">
//                     {errors?.hoabl_howdoyouspendyourtimeoff}
//                   </p>
//                 )}
//             </Grid>
//             <Grid item md={3} xs={6}>
//               <div style={{ padding: "0.2rem" }}>
//                 <p>Favorite sport:</p>
//               </div>
//             </Grid>
//             <Grid item md={3} xs={6}>
//               <Field
//                 as="select"
//                 label="Favorite sport"
//                 name="hoabl_favoritesport"
//                 className="select"
//                 style={
//                   (touched?.hoabl_favoritesport &&
//                     errors?.hoabl_favoritesport) ||
//                   (errors?.hoabl_favoritesport && submitCount)
//                     ? {
//                         borderColor: "rgb(211,47,47)",
//                         color: "rgb(211,47,47)",
//                       }
//                     : {}
//                 }
//               >
//                 <option value="">Choose...</option>
//                 {choices.hoabl_favoritesport.map((item) => (
//                   <option key={item.id} value={item.value}>
//                     {item.value}
//                   </option>
//                 ))}
//               </Field>
//               {touched?.hoabl_favoritesport && errors?.hoabl_favoritesport && (
//                 <p className="error">{errors?.hoabl_favoritesport}</p>
//               )}
//             </Grid>
//             <Grid item md={3} xs={6}>
//               <div style={{ padding: "0.2rem" }}>
//                 <p>Type of holidays you enjoy?</p>
//               </div>
//             </Grid>
//             <Grid item md={3} xs={6}>
//               <Field
//                 as="select"
//                 label="Type of holidays you enjoy ?"
//                 name="hoabl_typeofholidaysyouenjoy"
//                 className="select"
//                 style={
//                   (touched?.hoabl_typeofholidaysyouenjoy &&
//                     errors?.hoabl_typeofholidaysyouenjoy) ||
//                   (errors?.hoabl_typeofholidaysyouenjoy && submitCount)
//                     ? {
//                         borderColor: "rgb(211,47,47)",
//                         color: "rgb(211,47,47)",
//                       }
//                     : {}
//                 }
//               >
//                 <option value="">Choose...</option>
//                 {choices.hoabl_typeofholidaysyouenjoy.map((item) => (
//                   <option key={item.id} value={item.value}>
//                     {item.value}
//                   </option>
//                 ))}
//               </Field>
//               {touched?.hoabl_typeofholidaysyouenjoy &&
//                 errors?.hoabl_typeofholidaysyouenjoy && (
//                   <p className="error">
//                     {errors?.hoabl_typeofholidaysyouenjoy}
//                   </p>
//                 )}
//             </Grid>
//             <Grid item md={3} xs={6}>
//               <div style={{ padding: "0.2rem" }}>
//                 <p>What excites you from the list?</p>
//               </div>
//             </Grid>
//             <Grid item md={3} xs={6}>
//               <Field
//                 as="select"
//                 label="What excites you from the list?"
//                 name="hoabl_whatexcitesyoufromthelist"
//                 className="select"
//                 style={
//                   (touched?.hoabl_whatexcitesyoufromthelist &&
//                     errors?.hoabl_whatexcitesyoufromthelist) ||
//                   (errors?.hoabl_whatexcitesyoufromthelist && submitCount)
//                     ? {
//                         borderColor: "rgb(211,47,47)",
//                         color: "rgb(211,47,47)",
//                       }
//                     : {}
//                 }
//               >
//                 <option value="">Choose...</option>
//                 {choices.hoabl_whatexcitesyoufromthelist.map((item) => (
//                   <option key={item.id} value={item.value}>
//                     {item.value}
//                   </option>
//                 ))}
//               </Field>
//               {touched?.hoabl_whatexcitesyoufromthelist &&
//                 errors?.hoabl_whatexcitesyoufromthelist && (
//                   <p className="error">
//                     {errors?.hoabl_whatexcitesyoufromthelist}
//                   </p>
//                 )}
//             </Grid>
            {/* <Grid item md={3} xs={6}>
              <div style={{ padding: "0.2rem" }}>
                <p>What are your long-term investment goals?<span style={{color:"red"}}>*</span></p>
              </div>
            </Grid>
            <Grid item md={3} xs={6}>
              <Field
                as="select"
                label="What are your long-term investment goals?*"
                name="hoabl_whatareyourlongterminvestmentgoals"
                className="select"
                style={
                  (touched?.hoabl_whatareyourlongterminvestmentgoals &&
                    errors?.hoabl_whatareyourlongterminvestmentgoals) ||
                  (errors?.hoabl_whatareyourlongterminvestmentgoals &&
                    submitCount)
                    ? {
                        borderColor: "rgb(211,47,47)",
                        color: "rgb(211,47,47)",
                      }
                    : {}
                }
              >
                <option value="">Choose...</option>
                {choices.hoabl_whatareyourlongterminvestmentgoals.map(
                  (item) => (
                    <option key={item.id} value={item.value}>
                      {item.value}
                    </option>
                  )
                )}
              </Field>
              {touched?.hoabl_whatareyourlongterminvestmentgoals &&
                errors?.hoabl_whatareyourlongterminvestmentgoals && (
                  <p className="error">
                    {errors?.hoabl_whatareyourlongterminvestmentgoals}
                  </p>
                )}
            </Grid> */}
            // <Grid item md={3} xs={6}>
            //   <div style={{ padding: "0.2rem" }}>
            //     <p>What TV channels do you watch?</p>
            //   </div>
            // </Grid>
            // <Grid item md={3} xs={6}>
            //   <Field
            //     as="select"
            //     label="What TV channels do you watch?"
            //     name="hoabl_whattvchannelsdoyouwatch"
            //     className="select"
            //     style={
            //       (touched?.hoabl_whattvchannelsdoyouwatch &&
            //         errors?.hoabl_whattvchannelsdoyouwatch) ||
            //       (errors?.hoabl_whattvchannelsdoyouwatch && submitCount)
            //         ? {
            //             borderColor: "rgb(211,47,47)",
            //             color: "rgb(211,47,47)",
            //           }
            //         : {}
            //     }
            //   >
            //     <option value="">Choose...</option>
            //     {choices.hoabl_whattvchannelsdoyouwatch.map((item) => (
            //       <option key={item.id} value={item.value}>
            //         {item.value}
            //       </option>
            //     ))}
            //   </Field>
            //   {touched?.hoabl_whattvchannelsdoyouwatch &&
            //     errors?.hoabl_whattvchannelsdoyouwatch && (
            //       <p className="error">
            //         {errors?.hoabl_whattvchannelsdoyouwatch}
            //       </p>
            //     )}
            // </Grid>
            // <Grid item md={3} xs={6}>
            //   <div style={{ padding: "0.2rem" }}>
            //     <p>Where do you watch shows/movies/listen to music?</p>
            //   </div>
            // </Grid>
            // <Grid item md={3} xs={6}>
            //   <Field
            //     as="select"
            //     label="Where do you watch shows/movies/listen to music?"
            //     name="hoabl_wheredoyouwatchshowsmovieslistentomusic"
            //     className="select"
            //     style={
            //       (touched?.hoabl_wheredoyouwatchshowsmovieslistentomusic &&
            //         errors?.hoabl_wheredoyouwatchshowsmovieslistentomusic) ||
            //       (errors?.hoabl_wheredoyouwatchshowsmovieslistentomusic &&
            //         submitCount)
            //         ? {
            //             borderColor: "rgb(211,47,47)",
            //             color: "rgb(211,47,47)",
            //           }
            //         : {}
            //     }
            //   >
            //     <option value="">Choose...</option>
            //     {choices.hoabl_wheredoyouwatchshowsmovieslistentomusic.map(
            //       (item) => (
            //         <option key={item.id} value={item.value}>
            //           {item.value}
            //         </option>
            //       )
            //     )}
            //   </Field>
            //   {touched?.hoabl_wheredoyouwatchshowsmovieslistentomusic &&
            //     errors?.hoabl_wheredoyouwatchshowsmovieslistentomusic && (
            //       <p className="error">
            //         {errors?.hoabl_wheredoyouwatchshowsmovieslistentomusic}
            //       </p>
            //     )}
            // </Grid>
            // <Grid item md={3} xs={6}>
            //   <div style={{ padding: "0.2rem" }}>
            //     <p>Which Newspaper do you read?</p>
            //   </div>
            // </Grid>
            // <Grid item md={3} xs={6}>
            //   <Field
            //     as="select"
            //     label="Which Newspaper do you read?"
            //     name="hoabl_whichnewspaperdoyouread"
            //     className="select"
            //     style={
            //       (touched?.hoabl_whichnewspaperdoyouread &&
            //         errors?.hoabl_whichnewspaperdoyouread) ||
            //       (errors?.hoabl_whichnewspaperdoyouread && submitCount)
            //         ? {
            //             borderColor: "rgb(211,47,47)",
            //             color: "rgb(211,47,47)",
            //           }
            //         : {}
            //     }
            //   >
            //     <option value="">Choose...</option>
            //     {choices.hoabl_whichnewspaperdoyouread.map((item) => (
            //       <option key={item.id} value={item.value}>
            //         {item.value}
            //       </option>
            //     ))}
            //   </Field>
            //   {touched?.hoabl_whichnewspaperdoyouread &&
            //     errors?.hoabl_whichnewspaperdoyouread && (
            //       <p className="error">
            //         {errors?.hoabl_whichnewspaperdoyouread}
            //       </p>
            //     )}
            // </Grid>







