import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

export default function AlternateTimeline() {
  return (
    <Timeline position="alternate">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot color="success" />
          <TimelineConnector sx={{background:"#2F7C31"}} />
        </TimelineSeparator>
        <TimelineContent>Online Registration</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{background:"#FFD601"}} />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>Verification</TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          {/* <TimelineConnector /> */}
        </TimelineSeparator>
        <TimelineContent>Welcome to Bluechip Land</TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
