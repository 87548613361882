import React from 'react'
import { useNavigate } from "react-router-dom"

function LeadSFDCForm() {
    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(e.target.fullname.value, e.target.email.value, e.target.phone.value, e.target.city.value);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let payload = JSON.stringify({
            "fullname": e.target.fullname.value,
            "phone": e.target.phone.value,
            "email": e.target.email.value,
            "city": e.target.city.value
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: payload,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_BASE_URL + "/api/sfdc/create-sfdc-lead", requestOptions)
            .then(data => {
                return data.json();
            })
            .then(result => {
                console.log(result);
                if (result.response.returnCode === 1) {
                    navigate("/error")
                    return
                }
                navigate("/lead-sfdc-demo/submitted")
            })
            .catch(error => {
                navigate("/error")
                console.log('error', error)
            });
    }
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                textAlign: "center",
                alignItems: "center",
                marginTop: "5rem",
                marginBottom: "5rem",
                background: "whitesmoke",
                padding: "2rem",
                boxShadow: '10px 5px 5px grey'
            }}
        >
            <h2>Lead SFDC Form</h2>
            <form onSubmit={handleSubmit}>
                <div style={{ margin: "0.2rem 0 0 0", textAlign: "left" }}>
                    <label>Full Name:</label>
                    <br />
                    <input style={{ width: "20rem" }} type="text" name="fullname"></input>
                    <br />
                </div>
                <div style={{ margin: "0.2rem 0 0 0", textAlign: "left" }}>
                    <label>Phone:</label>
                    <br />
                    <input style={{ width: "20rem" }} type="phone" name="phone"></input>
                    <br />
                </div>
                <div style={{ margin: "0.2rem 0 0 0", textAlign: "left" }}>
                    <label>City:</label>
                    <br />
                    <input style={{ width: "20rem" }} type="text" name="city"></input>
                    <br />
                </div>
                <div style={{ margin: "0.2rem 0 0 0", textAlign: "left" }}>
                    <label>Email:</label>
                    <br />
                    <input style={{ width: "20rem" }} type="email" name="email"></input>
                    <br />
                </div>

                <button style={{ margin: "1rem 0", width: "10rem" }} type="submit">
                    Submit
                </button>
            </form>
        </div>
    );
}

export default LeadSFDCForm