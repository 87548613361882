import React,{ useState , useEffect} from 'react'


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

function Rough() {
  const [file, setFile] = useState()

  useEffect(() => {
    handleAuth()
  
    return () => {
      
    }
  }, [])

  const handleAuth = async() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var formdata = new FormData();
    formdata.append("grant_type", "refresh_token");
    formdata.append("client_id", "a211ea72-1742-4b31-a145-4a87d99d1471@677268ba-0f79-414b-8720-a836b70778f0");
    formdata.append("client_secret", "ixBSH/t6FBmBJ2aqiVcmfVY9z17WK7IBM+45k3W8GLs=");
    formdata.append("resource", "00000003-0000-0ff1-ce00-000000000000/hoabl.sharepoint.com@677268ba-0f79-414b-8720-a836b70778f0");
    formdata.append("code", "PAQABAAEAAAD--DLA3VO7QrddgJg7Wevrq9gvHSaKHdLFVuGXGiB5aZoK0rn3XprzrQpd9zlMIM466q-FI_X5pY_A0zzJCUBVKbTu-_B71Ehez4r92yoKyz-cVoR3qWsH7hVkNL2Fk6uAAxoBWhyZYTtCGRNS8jRUhSr9L86n3hzerrkhd50yHDPfXl2N_xN7sfgAayRqNyMjYPNLU9lovtt_ildXvE6ihgPavBmbuiKcxppae2RDTDzpPJrtRwTPtOLXarPsy3JZJC8i_DNxptDJaBpnZ-wXSsFhpSZmlJez-Mc90J3aVEAibkVgj6CTCCDG4Dnk_uE_VARsMRLIScZxZ9RsYhscuhML_5im-D1ZzUspDv5gxiAA");
    formdata.append("redirect_uri", "https://hoabl.sharepoint.com/SitePages/Home.aspx");
    formdata.append("refresh_token", "PAQABAAEAAAD--DLA3VO7QrddgJg7Wevr40tY46qVJXZFxKbbnmeFXQ43-oXewW6kJHjz-fISow2Aj2yQcJFSnE6hnRncH8k4EC72M4SF8HHfxRFYRdmDlrWg3uANnPYpDnNXsrCRO9Rf_HhvgO6Gcve4N20d-qKH7g_0HtRZyWxJA1zt75-i2Q7Y4QnSr5ct8IS-5vBFTSYzUx_og9ljbU5k7N-_kLSgQaTCTfqf8I4FlLsXn5edPMv1xsH02TrIAU_XQck__Ju3CrYcg6Ejczh6O6F7F-TYBsiUuaPiIDKxIMLtS5VSNCAA");
    var requestOptions = {
      method: 'GET',
      header: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    const response = await fetch("https://accounts.accesscontrol.windows.net/677268ba-0f79-414b-8720-a836b70778f0/tokens/OAuth/2", requestOptions)
    const res = await response.json()
    console.log(res)
  }
  

  const handleFileUpload = async() => {
    // console.log(file)
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvaG9hYmwuc2hhcmVwb2ludC5jb21ANjc3MjY4YmEtMGY3OS00MTRiLTg3MjAtYTgzNmI3MDc3OGYwIiwiaXNzIjoiMDAwMDAwMDEtMDAwMC0wMDAwLWMwMDAtMDAwMDAwMDAwMDAwQDY3NzI2OGJhLTBmNzktNDE0Yi04NzIwLWE4MzZiNzA3NzhmMCIsImlhdCI6MTY3OTEzODA1NCwibmJmIjoxNjc5MTM4MDU0LCJleHAiOjE2NzkxNjcxNTQsImFjdG9yIjoiYTIxMWVhNzItMTc0Mi00YjMxLWExNDUtNGE4N2Q5OWQxNDcxQDY3NzI2OGJhLTBmNzktNDE0Yi04NzIwLWE4MzZiNzA3NzhmMCIsImlkZW50aXR5cHJvdmlkZXIiOiJ1cm46ZmVkZXJhdGlvbjptaWNyb3NvZnRvbmxpbmUiLCJuYW1laWQiOiIxMDAzMjAwMjJBREU5NEZGIn0.eR6UhEiUb_o1bqotlRhzL-JqTY4iVcpPhH212wY3SRjqO9InDPUsXC5C7S0y5Fw-0Jc0vzTPL-c-5xr5Hhr5raJngvaeptm6UqzxQ3Zhw9nPg5EEwD543Qu80RV3GiKbosT7pN7zEvfh1SxrrwA8dkPpqnsiK_oy4P-kAal4tkfmPpkBbH3zxIwZXi9-eosWf6OEeO4lFwdLl0fkHc7feKEB1q5bLqEXyxDsNo7dz4LfQwCwMwl7pvJULUlr7jelYf9HatyP1jIQkb9kHN_isBpOMpTRtZHgASP2fUyovU0BfK4VKSp9hZo73_j7XSB8_KVd2jBTPDvB2i9-_iA0yg");
    // myHeaders.append("Accept", "application/json");
    // myHeaders.append("X-RequestDigest", "0xF79F2ECD2958BD437DF4189430039F954F3A48C268D3E8D7F53318B998BD10D79D3134DE47C0E9C650960CDC13FF545998DF28F5621F7BDE8D11AD102C1118A7,03 Jun 2022 11:25:58 -0000");
    // myHeaders.append("Content-Type", "application/json;odata=verbose");
    // myHeaders.append("If-Match", "*");
    // // var formdata = new FormData();
    // // formdata.append("file", file,file?.name );
    // // var raw = JSON.stringify({
    // //   file:file,
    // // });
    // // console.log(raw);
    // var requestOptions = {
    //   method: 'POST',
    //   header: myHeaders,
    //   body: file,
    //   redirect: 'follow'
    // };

    // fetch("https://hoabl.sharepoint.com/HOABL%20CRM/_api/web/GetFolderByServerRelativeUrl('/HOABL%20CRM/contact/Rutvik test Pimple_969b4c55b440ed11bba2002248d5d208/quote/Rutvik test Pimple_test 0001_2285516502644079a46509871e1afccf/Unverified Documents')/Files/add(url='rutv.png',overwrite=true)", requestOptions)

    
    // .then(response => response.text())
    // .then(result => console.log(result))
    // .catch(error => console.log('error', error));
    // // return res

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiIwMDAwMDAwMy0wMDAwLTBmZjEtY2UwMC0wMDAwMDAwMDAwMDAvaG9hYmwuc2hhcmVwb2ludC5jb21ANjc3MjY4YmEtMGY3OS00MTRiLTg3MjAtYTgzNmI3MDc3OGYwIiwiaXNzIjoiMDAwMDAwMDEtMDAwMC0wMDAwLWMwMDAtMDAwMDAwMDAwMDAwQDY3NzI2OGJhLTBmNzktNDE0Yi04NzIwLWE4MzZiNzA3NzhmMCIsImlhdCI6MTY3OTEzMjYwOCwibmJmIjoxNjc5MTMyNjA4LCJleHAiOjE2NzkxNjE3MDgsImFjdG9yIjoiYTIxMWVhNzItMTc0Mi00YjMxLWExNDUtNGE4N2Q5OWQxNDcxQDY3NzI2OGJhLTBmNzktNDE0Yi04NzIwLWE4MzZiNzA3NzhmMCIsImlkZW50aXR5cHJvdmlkZXIiOiJ1cm46ZmVkZXJhdGlvbjptaWNyb3NvZnRvbmxpbmUiLCJuYW1laWQiOiIxMDAzMjAwMjJBREU5NEZGIn0.Z9WqTjAw6_FFq3v3tUu2QvKZJnF-tsRxc_eRDOOlYOrmUxNJlZWk6KAMW38I-r1Bz1AMtHKMJ2AE75SbjkqyPEC5npyO9J3tXIlQ8MQMU9y-HPrksbAi3oYgBpezlRumqOV_kr0sJKbvPSwIqJhdLfQCORTiuq26M1J1WGwvA9mKehjoo-atwrTlfE5LFU6LLvhHCQAfbOcWD-muqPyjYViK767iMBXz54rgS4CXIb0v2cIuND0l9RntSYd9mFwHuUpmfS3YPIPkcgKB_sSbds1a8TWzRGPMkv6IPIReLUGpeeGfRB546k6NnrBgoexFuEb0V9D0PKzWjYfDlscplg");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("X-RequestDigest", "0xF79F2ECD2958BD437DF4189430039F954F3A48C268D3E8D7F53318B998BD10D79D3134DE47C0E9C650960CDC13FF545998DF28F5621F7BDE8D11AD102C1118A7,03 Jun 2022 11:25:58 -0000");
    myHeaders.append("Content-Type", "application/json;odata=verbose");
    myHeaders.append("If-Match", "*");

    // var file = "<file contents here>";
        // var file = fileInput;

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: file,
    redirect: 'follow'
    };

    // fetch("https://hoabl.sharepoint.com/_api/web/GetFolderByServerRelativeUrl('/hoabl_project/HOABL_A2283006FE8AEC1193B0000D3ACA1CC3')/Files/add(url='mg.jpg',overwrite=true)", requestOptions)


    fetch("https://hoabl.sharepoint.com/HOABL%20CRM/_api/web/GetFolderByServerRelativeUrl('/HOABL%20CRM/contact/Rutvik test Pimple_969b4c55b440ed11bba2002248d5d208/quote/Rutvik test Pimple_test 0001_2285516502644079a46509871e1afccf/Unverified Documents')/Files/add(url='Test.png',overwrite=true)", requestOptions)

    
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));
  }

  return (
    <div>
      <input type="file" onChange={(e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = function(event){
          const fileContents = event.target.result;
          setFile(fileContents);
        }
        reader.readAsArrayBuffer(file);

        
        // let obj = await toBase64(e.target.files[0])
        // console.log(obj)
        // setFile(obj)
      }} />
      <button onClick={handleFileUpload}>upload</button>
    </div>
  )
}

export default Rough