// Basic.js
import { ErrorMessage, Field, Form, Formik, FieldArray } from "formik";
import React, { useContext, useState } from "react";
import { FormContext } from "../index";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { choices } from "./choices";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { compareUrl } from "../../utility/sharepointUpload";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const customerOnboardingSchema = yup.object().shape({
  hoabl_primaryapplicantname: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_salutationprimaryapplicant: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_projectname: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_plotno: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_plotarea: yup.string().max(10),
  hoabl_specialoffers: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_primaryapplicantmobilenumber: yup
    .string()
    .matches(phoneRegExp, { message: "Not a valid Phone Number" })
    .required("Please add details"),
  hoabl_alternatemobilenoprimaryapplicant: yup
    .string()
    .matches(phoneRegExp, { message: "Not a valid Phone Number" }),
  hoabl_primaryapplicantmailid: yup
    .string()
    .email("Must be valid email")
    .max(100,"Max Characters cannot exceed 100")
    .required("Please add details"),
  hoabl_communicationaddressline2: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_addressline2: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_communicationaddressline3: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_addressline3: yup.string().max(100,"Max Characters cannot exceed 100"),
  hoabl_primaryapplicantaddress: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_primaryapplicantcity: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_primaryapplicantpostalcode: yup.string().min(6, "Postal code must be of 6 characters").max(6, "Postal code must be of 6 characters").required("Please add details"),
  hoabl_primaryapplicantcity: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_primaryapplicantstate: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_communicationaddress: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_communicationcity: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_communicationpostalcode: yup.string().min(6, "Postal code must be at least 6 characters").max(6, "Postal code must be at least 6 characters").required("Please add details"),
  hoabl_communicationstate: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_primaryapplicantpan: yup.string().min(10, "PAN Number must be at least 10 characters").max(10, "PAN Number must be at least 10 characters").required("Please add details").matches(/^[a-zA-Z0-9]+$/,"This field cannot contain white space and special character"),
  hoabl_adhaarcardnumber: yup.string().min(12, "Aadhaar Number must be at least 12 characters").max(12, "Aadhaar Number must be at least 12 characters").required("Please add details").matches(/^[a-zA-Z0-9]+$/,"This field cannot contain white space and special character"),
  hoabl_primaryapplicantdateofbirth: yup.date("Invalid date").required("Please enter valid date").max(new Date(), "Date cannot be in the future"),
  hoabl_primaryapplicantprofession: yup.string().required("Please add details"),
  // hoabl_namecoapplicant1: yup.string(),
  // hoabl_pannocoapplicant1: yup.string(),
  // hoabl_coapplicant1mobilenumber: yup.string(),
  // hoabl_coapplicant1profession: yup.string(),
  // hoabl_coapplicant1nameoftheorganization: yup.string(),
  // hoabl_coapplicant1designation: yup.string(),
  // hoabl_namecoapplicant2: yup.string(),
  // hoabl_pannocoapplicant2: yup.string(),
  // hoabl_coapplicant2mobilenumber: yup.string(),
  // hoabl_coapplicant2profession: yup.string(),
  // hoabl_coapplicant2nameoftheorganization: yup.string(),
  // hoabl_coapplicant2designation: yup.string(),
  // hoabl_namecoapplicant3: yup.string(),
  // hoabl_pannocoapplicant3: yup.string(),
  // hoabl_coapplicant3mobilenumber: yup.string(),
  hoabl_spousenameifapplicable: yup.string().max(100,"Max Characters cannot exceed 100"),
  co_applicants: yup.array(
    yup.object({
      hoabl_namecoapplicant: yup.string().max(100,"Max Characters cannot exceed 100").required('Please add details'),
      hoabl_salutationcoapplicant: yup.string().max(100,"Max Characters cannot exceed 100").required('Please add details'),
      hoabl_pannocoapplicant: yup.string().min(10, "PAN Number must be at least 10 characters").max(10, "PAN Number must be at least 10 characters").matches(/^[a-zA-Z0-9]+$/,"This field cannot contain white space and special character").required('Please add details'),
      hoabl_coapplicantmobilenumber: yup.string().max(100,"Max Characters cannot exceed 100"),
      hoabl_coapplicantprofession: yup.string().max(100,"Max Characters cannot exceed 100"),
      hoabl_dobcoapplicant: yup.date("Invalid date").max(new Date(), "Date cannot be in the future").required("Please add details"),
      hoabl_relationshipwithcoapplicant: yup.string().max(100,"Max Characters cannot exceed 100"),
      hoabl_coapplicantnameoftheorganization: yup.string().max(100,"Max Characters cannot exceed 100"),
      hoabl_coapplicantdesignation: yup.string().max(100,"Max Characters cannot exceed 100"),
      hoabl_coapplicantpan_upload: yup.mixed().required("Please upload required document"),
      hoabl_aadhaarnocoapplicant: yup.string().min(12, "Aadhaar Number must be at least 12 characters").max(12, "Aadhaar Number must be at least 12 characters").matches(/^[a-zA-Z0-9]+$/,"This field cannot contain white space and special character").required('Please add details'),
      hoabl_coapplicantaadhaar_upload: yup.mixed().required("Please upload required document"),
    })
  ),
  hoabl_pancard: yup.mixed().required("Please upload required document"),
  hoabl_aadhaarcard: yup.mixed().required("Please upload required document"),
  same_as_above: yup.string().required("Please add details"),
});

const CustomPhoneInput = ({ field, form, ...props }) => {

  return (
    <PhoneInput
      className="containerStyle"
      {...field}
      {...props}
      fullWidth
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={props.helperText ? props.helperText : form?.touched[field?.name] && form?.errors[field?.name]}
    />
  )
}

const TextInput = ({ field, form, ...props }) => {

  return (
    <TextField
    // variant="standard"
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={props.helperText ? props.helperText : form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton-co"  {...field} {...props}>
      {props.children}
    </button>
  );
};

function Basic({data}) {
  const [isloading, setIsloading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const navigate = useNavigate();
  let {
    id,
    applicant_name,
    project_name,
    plot_no,
    plot_area,
    special_offers,
    country_code,
    mobile_no,
    contact_id,
    email_id,
  } = data;
  // } = useParams();
  if (country_code === null){
    country_code = "+91"
  }

  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);
  const co_applicantsGroup = {
    hoabl_namecoapplicant: "",
    hoabl_salutationcoapplicant: "",
    hoabl_pannocoapplicant: "",
    hoabl_coapplicantmobilenumber: "",
    hoabl_coapplicantprofession: "",
    hoabl_dobcoapplicant: "",
    hoabl_relationshipwithcoapplicant: "",
    hoabl_coapplicantnameoftheorganization: "",
    hoabl_coapplicantdesignation: "",
    hoabl_coapplicantpan_upload: "",
    hoabl_aadhaarnocoapplicant: "",
    hoabl_coapplicantaadhaar_upload: "",
  };

  // console.log(mobile_no && mobile_no.replace('+',""))
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  

  return (
    <div style={{background:"rgb(255,255,255)", padding: "1rem", borderRadius:'10px' }}>
      <Formik
        initialValues={data && {
          ...formData,
          hoabl_primaryapplicantname: applicant_name,
          hoabl_projectname: project_name,
          hoabl_plotno: plot_no,
          hoabl_plotarea: "" + plot_area,
          hoabl_specialoffers: special_offers,
          hoabl_primaryapplicantmailid: email_id,
          // hoabl_phonecodeprimaryapplicant: country_code ? country_code : "+91",
          // hoabl_primaryapplicantmobilenumber: initialMobileNo,
          hoabl_primaryapplicantmobilenumber: (country_code && mobile_no) ? (country_code+mobile_no).replace('+',"") : "",
          co_applicants: !formData?.co_applicants ? [] : formData.co_applicants,
        }}
        validationSchema={customerOnboardingSchema}
        // validationSchema={{}}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          data.hoabl_phonecodeprimaryapplicant = country_code
          // console.log(values)
          let spouseDetails = values.co_applicants.filter(item => item.hoabl_relationshipwithcoapplicant === "Spouse")
          if (spouseDetails.length > 0){
            let val = spouseDetails[0]
            data.marital_status = "married"
            data.hoabl_spousenameifapplicable = val.hoabl_namecoapplicant
            data.hoabl_spousemobilenumber = val.hoabl_coapplicantmobilenumber
            data.hoabl_spousedob = val.hoabl_dobcoapplicant
            data.hoabl_spouseprofession = val.hoabl_coapplicantprofession
            data.hoabl_spousedesignation = val.hoabl_coapplicantdesignation
            data.hoabl_spouseorganisation = val.hoabl_coapplicantnameoftheorganization
          }
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        {({ touched, errors, values, submitCount, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                {/* <div style={{ padding: "1rem" }}> */}
                <h4 style={{ font: "Jost", textDecoration:'underline', fontWeight:'bold' }}>KYC Details</h4>
                <p style={{ font: "Jost", color:'#161617', fontWeight:'400' }}>These details will help us complete formalities faster and smoothly for you <span>☺️</span></p>
                {/* </div> */}
              </Grid>
              
              <Grid item md={6} xs={12}>
                <Field
                  name="hoabl_projectname"
                  label="Project Name"
                  fullWidth
                  disabled
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name="hoabl_plotno"
                  label="Plot no"
                  fullWidth
                  disabled
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name="hoabl_plotarea"
                  label="Plot area"
                  fullWidth
                  disabled
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name="hoabl_specialoffers"
                  label="Special offers"
                  fullWidth
                  disabled
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={2} xs={3}>
                <Field
                  name="hoabl_salutationprimaryapplicant"
                  label="Title*"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              <Grid item md={12} xs={12}>
                {/* <div style={{ padding: "1rem" }}> */}
                <h4 style={{ font: "Jost" }}>Primary Applicant Details</h4>
                <p>Contact Details</p>
                {/* </div> */}
              </Grid>
              <Grid item md={3} xs={3}>
                <Field
                  name="hoabl_salutationprimaryapplicant"
                  label="Title*"
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("hoabl_salutationprimaryapplicant",e.target.value)
                  }}
                  component={TextInput}
                >
                  {choices.title.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                </Field>
              </Grid>
              <Grid item md={9} xs={9}>
                <Field
                  name="hoabl_primaryapplicantname"
                  label="Name as per Pan card"
                  fullWidth
                  // helperText="Required as per PAN"
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Field
                  name="hoabl_primaryapplicantmobilenumber"
                  label="Mobile Number*"
                  disabled
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              <Grid item md={4} xs={12}>
                <Field
                  name="hoabl_primaryapplicantmobilenumber"
                  label="Mobile Number*"
                  disabled
                  specialLabel="Mobile Number*"
                  country='in'
                  // containerStyle={{opacity:'0.65'}}
                  // buttonStyle={{opacity:'0.65'}}
                  // inputStyle={{opacity:'0.65'}}
                  inputProps={{
                    name: 'hoabl_primaryapplicantmobilenumber',
                    // required: true,
                    // autoFocus: true
                  }}
                  component={CustomPhoneInput}
                  onSubmit={(value, data, event, formattedValue) => {
                    setFieldValue("hoabl_phonecodeprimaryapplicant","+"+data.dialCode)
                    setFieldValue("hoabl_primaryapplicantmobilenumber",value)
                    // console.log(value,"onsubmit")
                  }}
                />
                {touched?.hoabl_primaryapplicantmobilenumber&&
                  errors?.hoabl_primaryapplicantmobilenumber && (
                    <p className="error">{errors?.hoabl_primaryapplicantmobilenumber}</p>
                  )}
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Field
                  name="hoabl_alternatemobilenoprimaryapplicant"
                  label="Alternate Mobile Number"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              <Grid item md={4} xs={12}>
                <Field
                  name="hoabl_alternatemobilenoprimaryapplicant"
                  label="Alternate Mobile Number"
                  specialLabel="Alternate Mobile Number"
                  country='in'
                  // containerStyle={{opacity:'0.75'}}
                  // buttonStyle={{opacity:'0.75'}}
                  // inputStyle={{opacity:'0.75'}}
                  inputProps={{
                    name: 'hoabl_alternatemobilenoprimaryapplicant',
                    // required: true,
                    // autoFocus: true
                  }}
                  onChange={(value, data, event, formattedValue) => {
                    setFieldValue("hoabl_phonecodealternate","+"+data.dialCode)
                    setFieldValue("hoabl_alternatemobilenoprimaryapplicant",value)
                  }}
                  component={CustomPhoneInput}
                />
                {touched?.hoabl_alternatemobilenoprimaryapplicant&&
                  errors?.hoabl_alternatemobilenoprimaryapplicant && (
                    <p className="error">{errors?.hoabl_alternatemobilenoprimaryapplicant}</p>
                  )}
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  name="hoabl_primaryapplicantmailid"
                  label="Email ID*"
                  fullWidth
                  component={TextInput}
                />
              </Grid>

              <Grid item xs={12} sx={{ }}>
                <div style={{ backgroundColor: '#45601B', color:'#fff', width:'fit-content', padding:'0.5rem', borderRadius:'5px' }}> 
                  <h6 style={{ font: "Jost" }}>Permanent Address* as per Aadhaar</h6>
                </div>
              </Grid>
              {/* <Grid item md={6} xs={12}>
                <Field
                  name="hoabl_primaryapplicantaddress"
                  label="Primary Applicant Address"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name="hoabl_primaryapplicantcity"
                  label="City"
                  fullWidth
                  component={TextInput}
                />
              </Grid> */}
              
              {/*             
              <Grid item md={12} xs={12}>
                <Field
                  as="textarea"
                  name="hoabl_primaryapplicantaddress"
                  label="Primary Applicant Address"
                  style={
                    (touched?.hoabl_primaryapplicantaddress && errors?.hoabl_primaryapplicantaddress) ||
                    (errors?.hoabl_primaryapplicantaddress && submitCount)
                      ? {
                          borderColor: "rgb(211,47,47)",
                          color: "rgb(211,47,47)",
                        }
                      : {}
                  }
                />
                {touched?.hoabl_primaryapplicantaddress && errors?.hoabl_primaryapplicantaddress && (
                  <p className="error">{errors?.hoabl_primaryapplicantaddress}</p>
                )}
              </Grid>
              */}

              <Grid item md={12} xs={12}>
                <Field
                  name="hoabl_primaryapplicantaddress"
                  label="Address line 1"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Field
                  name="hoabl_addressline2"
                  label="Address line 2"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Field
                  name="hoabl_addressline3"
                  label="Address line 3"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <Field
                  name="hoabl_primaryapplicantcity"
                  label="City"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                {/* <Field
                  name="hoabl_primaryapplicantstate"
                  label="State"
                  fullWidth
                  component={TextInput}
                /> */}
                <Field
                  name="hoabl_primaryapplicantstate"
                  label="State"
                  required
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("hoabl_primaryapplicantstate",e.target.value)
                  }}
                  component={TextInput}
                >
                  {choices.states.map(item => <MenuItem key={item.code} value={item.value}>{item.value}</MenuItem>)}
                </Field>
              </Grid>
              
              <Grid item md={4} xs={12}>
                <Field
                  name="hoabl_primaryapplicantpostalcode"
                  label="Postal code"
                  required
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
              </Grid>
              
              <Grid item md={12} xs={12}>
                <div style={{ backgroundColor: '#45601B', color:'#fff', width:'fit-content', padding:'0.5rem', borderRadius:'5px', minWidth:'250px' }}> 
                  <h6 style={{ font: "Jost" }}>Communication Address*</h6>
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <Field
                  name="same_as_above"
                  label="Same as Above*"
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("same_as_above",e.target.value)
                    if (e.target.value==="Yes") {
                      setFieldValue(
                        "hoabl_communicationaddress",
                        values.hoabl_primaryapplicantaddress
                      );
                      setFieldValue(
                        "hoabl_communicationaddressline2",
                        values.hoabl_addressline2
                      );
                      setFieldValue(
                        "hoabl_communicationaddressline3",
                        values.hoabl_addressline3
                      );
                      // setFieldValue(
                      //   "hoabl_communicationcity",
                      //   values.hoabl_primaryapplicantcity
                      // );
                      setFieldValue(
                        "hoabl_communicationpostalcode",
                        values.hoabl_primaryapplicantpostalcode
                      );
                      setFieldValue(
                        "hoabl_communicationstate",
                        values.hoabl_primaryapplicantstate
                      );
                      setFieldValue(
                        "hoabl_communicationcity",
                        values.hoabl_primaryapplicantcity
                      );
                    } else {
                      values.hoabl_communicationaddress = "";
                      values.hoabl_communicationaddressline2 = "";
                      values.hoabl_communicationaddressline3 = "";
                      values.hoabl_communicationcity = "";
                      values.hoabl_communicationpostalcode = "";
                      values.hoabl_communicationstate = "";
                    }
                  }}
                  component={TextInput}
                >
                  <MenuItem key="1" value="Yes">Yes</MenuItem>
                  <MenuItem key="2" value="No">No</MenuItem>
                </Field>
              </Grid>
              <Grid item md={9} xs={0}></Grid>
              {(values?.same_as_above === "No") && (
                <>
                {/* <Grid item md={6} xs={12}>
                  <Field
                    name="hoabl_communicationaddress"
                    label="Communication Address"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="hoabl_communicationcity"
                    label="City"
                    fullWidth
                    component={TextInput}
                  />
                </Grid> */}

                {/*               
                <Grid item md={12} xs={12}>
                  
                  <Field
                    as="textarea"
                    name="hoabl_communicationaddress"
                    label="Communication Address"
                    style={
                      (touched?.hoabl_communicationaddress && errors?.hoabl_communicationaddress) ||
                      (errors?.hoabl_communicationaddress && submitCount)
                        ? {
                            borderColor: "rgb(211,47,47)",
                            color: "rgb(211,47,47)",
                          }
                        : {}
                    }
                  />
                  {touched?.hoabl_communicationaddress && errors?.hoabl_communicationaddress && (
                    <p className="error">{errors?.hoabl_communicationaddress}</p>
                  )}
                </Grid>
                */}
                
                <Grid item md={12} xs={12}>
                  <Field
                    name="hoabl_communicationaddress"
                    label="Address line 1"
                    required
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Field
                    name="hoabl_communicationaddressline2"
                    label="Address line 2"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Field
                    name="hoabl_communicationaddressline3"
                    label="Address line 3"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <Field
                    name="hoabl_communicationcity"
                    label="City"
                    required
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  {/* <Field
                    name="hoabl_communicationstate"
                    label="State"
                    fullWidth
                    component={TextInput}
                  /> */}
                  <Field
                    name="hoabl_communicationstate"
                    label="State"
                    required
                    fullWidth
                    select={true}
                    SelectProps={{
                      onClose: () => {
                        setTimeout(() => {
                          document.activeElement.blur();
                        }, 0);
                      },
                    }}
                    onChange={(e) => {
                      setFieldValue("hoabl_communicationstate",e.target.value)
                    }}
                    component={TextInput}
                  >
                    {choices.states.map(item => <MenuItem key={item.code} value={item.value}>{item.value}</MenuItem>)}
                  </Field>
                </Grid>

                <Grid item md={4} xs={12}>
                  <Field
                    name="hoabl_communicationpostalcode"
                    label="Postal code"
                    required
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                </Grid>

                </>
              )}

              <Grid item md={12} xs={12}>
                <div style={{ backgroundColor: '#45601B', color:'#fff', width:'fit-content', padding:'0.5rem', borderRadius:'5px', minWidth:'250px' }}> 
                  <h6 style={{ font: "Jost" }}>Other</h6>
                </div>
              </Grid>

              {/* <Grid item md={3} xs={6}>
                <div style={{ padding: "0.5rem" }}>
                  <p>Date of Birth:</p>
                </div>
              </Grid> */}
              <Grid item md={6} xs={6}>
                <Field
                  type="date"
                  name="hoabl_primaryapplicantdateofbirth"
                  label="Date of Birth"
                  InputLabelProps={{ shrink: true, required: true }}
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name="hoabl_primaryapplicantprofession"
                  label="Profession*"
                  fullWidth
                  select={true}
                  SelectProps={{
                    onClose: () => {
                      setTimeout(() => {
                        document.activeElement.blur();
                      }, 0);
                    },
                  }}
                  onChange={(e) => {
                    if (e.target.value === "Home Maker"){
                      setFieldValue("hoabl_primaryapplicantnameoftheorganization","")
                      setFieldValue("hoabl_primaryapplicantdesignation","")
                    }
                    setFieldValue("hoabl_primaryapplicantprofession",e.target.value)
                  }}
                  component={TextInput}
                >
                  {choices.profession.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                </Field>
              </Grid>
              {/* <Grid item md={3} xs={6}>
                <div style={{ padding: "0.2rem" }}>
                  <p>Primary Applicant Profession:<span style={{color:"red"}}>*</span></p>
                </div>
              </Grid>
              <Grid item md={3} xs={6}>
                <Field
                  as="select"
                  label="Profession*"
                  name="hoabl_primaryapplicantprofession"
                  className="select"
                  style={
                    (touched?.hoabl_primaryapplicantprofession &&
                      errors?.hoabl_primaryapplicantprofession) ||
                    (errors?.hoabl_primaryapplicantprofession && submitCount)
                      ? {
                          borderColor: "rgb(211,47,47)",
                          color: "rgb(211,47,47)",
                        }
                      : {}
                  }
                >
                  <option value="">Choose...</option>
                  {choices.profession.map((item) => (
                    <option key={item.id} value={item.value}>
                      {item.value}
                    </option>
                  ))}
                </Field>
                {touched?.hoabl_primaryapplicantprofession &&
                  errors?.hoabl_primaryapplicantprofession && (
                    <p className="error">
                      {errors?.hoabl_primaryapplicantprofession}
                    </p>
                  )}
              </Grid> */}
              { (values?.hoabl_primaryapplicantprofession === "Salaried" || values?.hoabl_primaryapplicantprofession === "Self Employed" || values?.hoabl_primaryapplicantprofession === "Professional" || values?.hoabl_primaryapplicantprofession === "Others") && (
                <>
                <Grid item md={6} xs={12}>
                  <Field
                    name="hoabl_primaryapplicantnameoftheorganization"
                    label="Name of the organization"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    name="hoabl_primaryapplicantdesignation"
                    label="Designation"
                    fullWidth
                    component={TextInput}
                  />
                </Grid>
                </>
              )}

              <Grid item md={5} xs={10}>
                <Field
                  type="text"
                  name="hoabl_primaryapplicantpan"
                  label="PAN Number*"
                  fullWidth
                  component={TextInput}
                />
                {touched?.hoabl_primaryapplicantpan &&
                  errors?.hoabl_pancard && (
                    <p className="error">{errors?.hoabl_pancard}</p>
                  )}
              </Grid>
              <Grid container item md={1} xs={2} direction="row" justifyContent="flex-end" alignItems="center">
                <div style={{ padding: "1rem 0 1rem 0" }}>
                  <label for="hoabl_pancard">
                    <CloudUploadIcon />
                    <span style={{ color: "rgb(211,47,47)" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    id="hoabl_pancard"
                    name="hoabl_pancard"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setFieldValue(
                        "hoabl_pancard_name",
                        file.name
                      );
                      const reader = new FileReader();
                      reader.onload = function(event){
                        const fileContents = event.target.result;
                        setFieldValue(
                          "hoabl_pancard",
                          fileContents
                        );
                        // setFile(fileContents);
                      }
                      reader.readAsArrayBuffer(file);
                      // let file = await toBase64(event.target.files[0])
                      // console.log(file)
                      // setFieldValue(
                      //   "hoabl_pancard",
                      //   event.target.files[0]
                      // );
                      // setFieldValue(
                      //   "hoabl_pancard",
                      //   file
                      // );
                    }}
                    hidden
                  />
                </div>
              </Grid>
              <Grid item md={5} xs={10}>
                <Field
                  type="text"
                  name="hoabl_adhaarcardnumber"
                  label="Aadhaar Number*"
                  fullWidth
                  component={TextInput}
                />
                {touched?.hoabl_adhaarcardnumber &&
                  errors?.hoabl_aadhaarcard && (
                    <p className="error">{errors?.hoabl_aadhaarcard}</p>
                  )}
              </Grid>
              <Grid container item md={1} xs={2} direction="row" justifyContent="flex-end" alignItems="center">
                <div style={{ padding: "1rem 0 1rem 0" }}>
                  <label for="hoabl_aadhaarcard">
                    <CloudUploadIcon />
                    <span style={{ color: "rgb(211,47,47)" }}>*</span>
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    id="hoabl_aadhaarcard"
                    name="hoabl_aadhaarcard"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      setFieldValue(
                        "hoabl_aadhaarcard_name",
                        file.name
                      );
                      const reader = new FileReader();
                      reader.onload = function(event){
                        const fileContents = event.target.result;
                        setFieldValue(
                          "hoabl_aadhaarcard",
                          fileContents
                        );
                        // setFile(fileContents);
                      }
                      reader.readAsArrayBuffer(file);
                      
                    }}
                    hidden
                  />
                </div>
              </Grid>
              

              <FieldArray name="co_applicants">
                {({ push, remove }) => (
                  <Grid container spacing={2} sx={{ marginTop: 2, paddingX: 2 }}>
                    <Grid item xs={12} sx={{ display: "flex" }}>
                      {/* <div style={{ padding: "0.5rem" }}> */}
                      <h4 style={{ font: "Jost" }}>Co-Applicant Details</h4>
                      {/* </div> */}
                    </Grid>
                    {values?.co_applicants?.length < 1 && (
                      <Tooltip title="Add Co-Applicant">
                        <Fab
                          sx={{ marginLeft: "1rem" }}
                          size="small"
                          className="registrationbutton"
                          onClick={() => push(co_applicantsGroup)}
                        >
                          <AddIcon sx={{ color: "white" }} />
                        </Fab>
                      </Tooltip>
                    )}
                    {values?.co_applicants?.map((_, index) => (
                      <>
                        <Grid item xs={12} sx={{ display: "flex" }}>
                          <div style={{ padding: "0.7rem" }}>
                            <h6 style={{ font: "Jost" }}>
                              Co-Applicant {index + 1}
                            </h6>
                          </div>
                          {values.co_applicants.length === index + 1 && (
                            <>
                              {index >= 0 && (
                                <Tooltip title="Delete Co-Applicant">
                                  <Fab
                                    sx={{ marginLeft: "1rem" }}
                                    size="small"
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteIcon />
                                  </Fab>
                                </Tooltip>
                              )}
                              {index < 4 && (
                                <Tooltip title="Add Co-Applicant">
                                  <Fab
                                    sx={{ marginLeft: "1rem" }}
                                    size="small"
                                    className="registrationbutton"
                                    onClick={() => push(co_applicantsGroup)}
                                  >
                                    <AddIcon sx={{ color: "white" }} />
                                  </Fab>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Grid>
                        {/* <Grid item md={2} xs={3}>
                          <Field
                            name={`co_applicants.${index}.hoabl_salutationcoapplicant`}
                            label="Title"
                            fullWidth
                            component={TextInput}
                          />
                        </Grid> */}
                        <Grid item md={1} xs={3}>
                          <Field
                            name={`co_applicants.${index}.hoabl_salutationcoapplicant`}
                            label="Title"
                            fullWidth
                            select={true}
                            required
                            SelectProps={{
                              onClose: () => {
                                setTimeout(() => {
                                  document.activeElement.blur();
                                }, 0);
                              },
                            }}
                            onChange={(e) => setFieldValue(`co_applicants.${index}.hoabl_salutationcoapplicant`,e.target.value)}
                            component={TextInput}
                          >
                            {choices.title.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                          </Field>
                          {
                              touched &&
                              Object.keys(touched).length > 0 &&
                              touched.hasOwnProperty("co_applicants") &&
                              touched["co_applicants"].length > 0 &&
                              touched["co_applicants"][index] &&
                              touched["co_applicants"][index].hasOwnProperty("hoabl_salutationcoapplicant") && 
                              touched["co_applicants"][index]["hoabl_salutationcoapplicant"] &&
                              errors &&
                              Object.keys(errors).length > 0 &&
                              errors.hasOwnProperty("co_applicants") && 
                              errors["co_applicants"].length > 0 &&
                              errors["co_applicants"][index] &&
                              errors["co_applicants"][index].hasOwnProperty("hoabl_salutationcoapplicant") &&
                              (
                                <p className="error" style={{marginBottom:'5px'}}>{errors["co_applicants"][index]["hoabl_salutationcoapplicant"]}</p>
                              )
                          }
                        </Grid>
                        <Grid item md={5} xs={9}>
                          <Field
                            name={`co_applicants.${index}.hoabl_namecoapplicant`}
                            label={`Co-Applicant ${index + 1} Name`}
                            fullWidth
                            required
                            component={TextInput}
                          />
                          {
                              touched &&
                              Object.keys(touched).length > 0 &&
                              touched.hasOwnProperty("co_applicants") &&
                              touched["co_applicants"].length > 0 &&
                              touched["co_applicants"][index] &&
                              touched["co_applicants"][index].hasOwnProperty("hoabl_namecoapplicant") && 
                              touched["co_applicants"][index]["hoabl_namecoapplicant"] &&
                              errors &&
                              Object.keys(errors).length > 0 &&
                              errors.hasOwnProperty("co_applicants") && 
                              errors["co_applicants"].length > 0 &&
                              errors["co_applicants"][index] &&
                              errors["co_applicants"][index].hasOwnProperty("hoabl_namecoapplicant") &&
                              (
                                <p className="error" style={{marginBottom:'5px'}}>{errors["co_applicants"][index]["hoabl_namecoapplicant"]}</p>
                              )
                          }
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name={`co_applicants.${index}.hoabl_relationshipwithcoapplicant`}
                            label={`Co-Applicant ${index + 1} Relation`}
                            fullWidth
                            select={true}
                            SelectProps={{
                              onClose: () => {
                                setTimeout(() => {
                                  document.activeElement.blur();
                                }, 0);
                              },
                            }}
                            onChange={(e) => {
                              setFieldValue(`co_applicants.${index}.hoabl_relationshipwithcoapplicant`,e.target.value)
                            }}
                            component={TextInput}
                          >
                            {choices.relation.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                          </Field>
                        </Grid>
                        {/* <Grid item md={3} xs={6}>
                          <div style={{ padding: "0.2rem" }}>
                            <p>Co-Applicant {index + 1} Relation:<span style={{color:"red"}}>*</span></p>
                          </div>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <Field
                            as="select"
                            label={`Co-Applicant ${index + 1} Relation*`}
                            name={`co_applicants.${index}.hoabl_relationshipwithcoapplicant`}
                            className="select"
                            
                          >
                            <option value="">Choose...</option>
                            {choices.relation.map((item) => (
                              <option key={item.id} value={item.value}>
                                {item.value}
                              </option>
                            ))}
                          </Field>
                          
                        </Grid> */}
                        <Grid item md={5} xs={10}>
                          <Field
                            type="text"
                            name={`co_applicants.${index}.hoabl_pannocoapplicant`}
                            label={`Co-Applicant ${index + 1} PAN Number`}
                            fullWidth
                            required
                            component={TextInput}
                          />
                          {
                              touched &&
                              Object.keys(touched).length > 0 &&
                              touched.hasOwnProperty("co_applicants") &&
                              touched["co_applicants"].length > 0 &&
                              touched["co_applicants"][index] &&
                              touched["co_applicants"][index].hasOwnProperty("hoabl_pannocoapplicant") && 
                              touched["co_applicants"][index]["hoabl_pannocoapplicant"] &&
                              errors &&
                              Object.keys(errors).length > 0 &&
                              errors.hasOwnProperty("co_applicants") && 
                              errors["co_applicants"].length > 0 &&
                              errors["co_applicants"][index] &&
                              errors["co_applicants"][index].hasOwnProperty("hoabl_pannocoapplicant") &&
                              (
                                <p className="error" style={{marginBottom:'5px'}}>{errors["co_applicants"][index]["hoabl_pannocoapplicant"]}</p>
                              )
                          }

                          {touched?.co_applicants && errors?.co_applicants && touched?.co_applicants[index]?.hoabl_pannocoapplicant &&
                            errors?.co_applicants[index]?.hoabl_coapplicantpan_upload && (
                              <p className="error" style={{marginBottom:'5px'}}>{errors?.co_applicants[index].hoabl_coapplicantpan_upload}</p>
                            )}
                        </Grid>
                        <Grid container item md={1} xs={2} direction="row" justifyContent="flex-end" alignItems="center">
                          <div style={{ padding: "1rem 0 1rem 0" }}>
                            <label for={`co_applicants.${index}.hoabl_coapplicantpan_upload`}>
                              <CloudUploadIcon />
                              <span style={{ color: "rgb(211,47,47)" }}>*</span>
                            </label>
                            <input
                              type="file"
                              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                              id={`co_applicants.${index}.hoabl_coapplicantpan_upload`}
                              name={`co_applicants.${index}.hoabl_coapplicantpan_upload`}
                              onChange={(e) => {
                                const file = e.target.files[0];
                                setFieldValue(
                                  `co_applicants.${index}.hoabl_coapplicantpan_upload_name`,
                                  file.name
                                );
                                const reader = new FileReader();
                                reader.onload = function(event){
                                  const fileContents = event.target.result;
                                  setFieldValue(
                                    `co_applicants.${index}.hoabl_coapplicantpan_upload`,
                                    fileContents
                                  );
                                  // setFile(fileContents);
                                }
                                reader.readAsArrayBuffer(file);
                                // let file = await toBase64(event.target.files[0])
                                // console.log(file)
                                // setFieldValue(
                                //   "hoabl_pancard",
                                //   event.target.files[0]
                                // );
                                // setFieldValue(
                                //   "hoabl_pancard",
                                //   file
                                // );
                              }}
                              hidden
                            />
                          </div>
                        </Grid>
                        <Grid item md={5} xs={10}>
                          <Field
                            type="text"
                            name={`co_applicants.${index}.hoabl_aadhaarnocoapplicant`}
                            label={`Co-Applicant ${index + 1} Aadhaar Number`}
                            fullWidth
                            required
                            component={TextInput}
                          />
                          { 
                              touched &&
                              Object.keys(touched).length > 0 &&
                              touched.hasOwnProperty("co_applicants") &&
                              touched["co_applicants"].length > 0 &&
                              touched["co_applicants"][index] &&
                              touched["co_applicants"][index].hasOwnProperty("hoabl_aadhaarnocoapplicant") && 
                              touched["co_applicants"][index]["hoabl_aadhaarnocoapplicant"] &&
                              errors &&
                              Object.keys(errors).length > 0 &&
                              errors.hasOwnProperty("co_applicants") && 
                              errors["co_applicants"].length > 0 &&
                              errors["co_applicants"][index] &&
                              errors["co_applicants"][index].hasOwnProperty("hoabl_aadhaarnocoapplicant") &&
                              (
                                <p className="error" style={{marginBottom:'5px'}}>{errors["co_applicants"][index]["hoabl_aadhaarnocoapplicant"]}</p>
                              )
                          }

                          {touched?.co_applicants && errors?.co_applicants && touched?.co_applicants[index]?.hoabl_aadhaarnocoapplicant &&
                            errors?.co_applicants[index]?.hoabl_coapplicantaadhaar_upload && (
                              <p className="error" style={{marginBottom:'5px'}}>{errors?.co_applicants[index].hoabl_coapplicantaadhaar_upload}</p>
                            )}
                        </Grid>
                        <Grid container item md={1} xs={2} direction="row" justifyContent="flex-end" alignItems="center">
                          <div style={{ padding: "1rem 0 1rem 0" }}>
                            <label for={`co_applicants.${index}.hoabl_coapplicantaadhaar_upload`}>
                              <CloudUploadIcon />
                              <span style={{ color: "rgb(211,47,47)" }}>*</span>
                            </label>
                            <input
                              type="file"
                              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                              id={`co_applicants.${index}.hoabl_coapplicantaadhaar_upload`}
                              name={`co_applicants.${index}.hoabl_coapplicantaadhaar_upload`}
                              onChange={(e) => {
                                const file = e.target.files[0];
                                setFieldValue(
                                  `co_applicants.${index}.hoabl_coapplicantaadhaar_upload_name`,
                                  file.name
                                );
                                const reader = new FileReader();
                                reader.onload = function(event){
                                  const fileContents = event.target.result;
                                  setFieldValue(
                                    `co_applicants.${index}.hoabl_coapplicantaadhaar_upload`,
                                    fileContents
                                  );
                                  // setFile(fileContents);
                                }
                                reader.readAsArrayBuffer(file);
                                
                              }}
                              hidden
                            />
                          </div>
                        </Grid>
                        {/* <Grid item md={6} xs={12}>
                          <Field
                            name={`co_applicants.${index}.hoabl_coapplicantmobilenumber`}
                            label={`Co-Applicant ${index + 1} Mobile Number`}
                            fullWidth
                            component={TextInput}
                          />
                        </Grid> */}
                        <Grid item md={6} xs={12}>
                          <Field
                            name={`co_applicants.${index}.hoabl_coapplicantmobilenumber`}
                            label={`Co-Applicant ${index + 1} Mobile Number`}
                            specialLabel={`Co-Applicant ${index + 1} Mobile Number`}
                            inputProps={{
                              name: `co_applicants.${index}.hoabl_coapplicantmobilenumber`,
                              // required: true,
                              // autoFocus: true
                            }}
                            country='in'
                            // containerStyle={{opacity:'0.75'}}
                            // buttonStyle={{opacity:'0.75'}}
                            // inputStyle={{opacity:'0.75'}}
                            onChange={(value, data, event, formattedValue) => {
                              setFieldValue(`hoabl_phonecodecoapplicant${index+1}`,"+"+data.dialCode)
                              setFieldValue(`co_applicants.${index}.hoabl_coapplicantmobilenumber`,value)
                            }}
                            component={CustomPhoneInput}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                        <Field
                          name={`co_applicants.${index}.hoabl_coapplicantprofession`}
                          label={`Co-Applicant ${index + 1} Profession`}
                          fullWidth
                          select={true}
                          SelectProps={{
                            onClose: () => {
                              setTimeout(() => {
                                document.activeElement.blur();
                              }, 0);
                            },
                          }}
                          onChange={(e) => {
                            setFieldValue(`co_applicants.${index}.hoabl_coapplicantprofession`,e.target.value)
                          }}
                          component={TextInput}
                        >
                          {choices.profession.map(item => <MenuItem key={item.id} value={item.value}>{item.value}</MenuItem>)}
                        </Field>
                      </Grid>
                        {/* <Grid item md={3} xs={6}>
                          <div style={{ padding: "0.2rem" }}>
                            <p>Co-Applicant {index + 1} Profession:<span style={{color:"red"}}>*</span></p>
                          </div>
                        </Grid>
                        <Grid item md={3} xs={6}>
                          <Field
                            as="select"
                            label={`Co-Applicant ${index + 1} Profession*`}
                            name={`co_applicants.${index}.hoabl_coapplicantprofession`}
                            className="select"
                            
                          >
                            <option value="">Choose...</option>
                            {choices.profession.map((item) => (
                              <option key={item.id} value={item.value}>
                                {item.value}
                              </option>
                            ))}
                          </Field>
                          
                        </Grid> */}
                        <Grid item md={6} xs={12}>
                          <Field
                            name={`co_applicants.${index}.hoabl_coapplicantnameoftheorganization`}
                            label={`Co-Applicant ${
                              index + 1
                            } Name of the organization`}
                            fullWidth
                            component={TextInput}
                          />
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <Field
                            name={`co_applicants.${index}.hoabl_coapplicantdesignation`}
                            label={`Co-Applicant ${index + 1} Designation`}
                            fullWidth
                            component={TextInput}
                          />
                        </Grid>
                        {/* <Grid item md={3} xs={6}>
                          <div style={{ padding: "0.5rem" }}>
                            <p>Co-Applicant {index + 1} Date of Birth:</p>
                          </div>
                        </Grid> */}
                        <Grid item md={6} xs={6}>
                          <Field
                            type="date"
                            max={Date.now()}
                            required
                            name={`co_applicants.${index}.hoabl_dobcoapplicant`}
                            label={`Co-Applicant ${index + 1} Date of Birth`}
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            component={TextInput}
                          />
                          {
                            touched &&
                            Object.keys(touched).length > 0 &&
                            touched.hasOwnProperty("co_applicants") &&
                            touched["co_applicants"].length > 0 &&
                            touched["co_applicants"][index] &&
                            touched["co_applicants"][index].hasOwnProperty("hoabl_dobcoapplicant") && 
                            touched["co_applicants"][index]["hoabl_dobcoapplicant"] &&
                            errors &&
                            Object.keys(errors).length > 0 &&
                            errors.hasOwnProperty("co_applicants") && 
                            errors["co_applicants"].length > 0 &&
                            errors["co_applicants"][index] &&
                            errors["co_applicants"][index].hasOwnProperty("hoabl_dobcoapplicant") &&
                            (
                              <p className="error">{errors["co_applicants"][index]["hoabl_dobcoapplicant"]}</p>
                            )
                          }
                        </Grid>
                      </>
                    ))}
                  </Grid>
                )}
              </FieldArray>
              {/* <Grid item md={12} xs={12}>
                <p style={{ font: "Jost" }}>*All details shared would appear in the agreement. Any change if required would need to be intimated to your Service Manager before finalization of the agreement.</p>
              </Grid> */}
              <Grid item md={6} xs={12}></Grid>
              <Grid item md={10} xs={8}></Grid>

              <Grid item md={2} xs={4}>
                {submitClicked && errors && (
                  <p className="error">Please check the form for validations*</p>
                )}
                <Field
                  name="submit"
                  type="submit"
                  component={CustomButton}
                  onClick={(e) => setSubmitClicked(true)}
                >
                  {isloading ? (
                    <CircularProgress sx={{ color: "#FFFFFF" }} size="1.5rem" />
                  ) : (
                    <span style={{fontWeight:'bold'}} >Next</span>
                  )}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Basic;
