import React from 'react'
import notfound from '../assets/HTML-404-Page-with-SVG.png'

function NotFound() {
  return (
    <div style={{display:"flex", height:"75vh", justifyContent:"center", alignContent:"center",alignItems:"center", }}>
      {/* <h1>
        Error 404
      </h1><br/>
      <h1>
        Page Not Found
      </h1> */}
      <img src={notfound} height="500px"/>
    </div>
  )
}

export default NotFound