// App.js
import { createContext, useEffect, useState } from "react";
import hoabl_logo from "../../assets/the house of abhinandan lodha logo-02.png"
import Step from "./Step";
import Stepper from "./Stepper";
import { sharePointAuth } from "../utility/sharepointUpload";
import { getBookingData } from "../utility/crmData";
import { useNavigate, useParams } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import backgroundImg from '../../assets/pexels-lucas-pezeta-2423412_low.jpeg'

export const FormContext = createContext();


// const getData = async () => {
//   const res = await axios.get('https://geolocation-db.com/json/')
//   const res = await axios.get('https://api.ipify.org/?format=json')
//   console.log(res.data);
//   setIP(res.data.IPv4)
// }

function MultiStepCustomerOnboarding() {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({});
  // console.log(formData)
  const [bookingData, setBookingData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const {
    bookingId
  } = useParams();

  useEffect(() => {
    sharePointAuth()

    async function fetchData(bookingId) {
      let bookingInfo = await getBookingData(bookingId);
      if(bookingInfo && bookingInfo?.is_form_submitted){
        navigate("/customer-onboarding/thanks");
      } else {
        setBookingData(bookingInfo);
      }
    }
    fetchData(bookingId);
    
    // Delay added to fetch the data
    const delay = 3000; // 3 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    // Clean up the timer
    return () => clearTimeout(timer);
  }, [])

  
  if (isLoading) {
    // return <h3>Loading...</h3>;
    return <BeatLoader color="#464e4c" />
  }

  return (
    <div className="cover-image" style={{ padding: "1rem", display:'flex', justifyContent:'center',width:'100%', height:'100%', fontFamily:'futuraFont' }}>
      <div style={{width:"80%", minWidth:"360px" }}>
        <div style={{ marginBottom:"1rem", color:"white", borderRadius:"5px", alignContent:"center", alignItems:"center",textAlign:"center"}}>
          <h2 style={{color:"#FFFFFF"}}>WELCOME TO</h2>
          <img src={hoabl_logo} width="150px" />
        </div>
        <FormContext.Provider
          value={{ activeStepIndex, setActiveStepIndex, formData, setFormData }}
        >
          <div >
            <Stepper />
            {bookingData ? (<Step data={bookingData}/>) : ( <h1>Loading...</h1>)}
          </div>
        </FormContext.Provider>
      </div>
    </div>
  );
}

export default MultiStepCustomerOnboarding;
