import React from 'react'

function Welcome() {
  return (
    <div style={{display:"flex", height:"75vh", justifyContent:"center", alignContent:"center",alignItems:"center"}}>
      <h1>
        Welcome to HOABL Forms
      </h1>
    </div>
  )
}

export default Welcome