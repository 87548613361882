import React, { useState, useEffect } from "react";
import { Formik, Form, Field, useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {CircleLoader,DotLoader,RingLoader,SyncLoader,ClockLoader,PacmanLoader} from "react-spinners";
import hoabl_logo from "../../assets/the house of abhinandan lodha logo-02.png"
import {useNavigate, useParams} from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import {NotificationContainer, NotificationManager} from 'react-notifications';
// import './registration.css';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const vendorSchema = yup.object().shape({
  org_name: yup.string().required("This is Required"),
  type_of_business: yup.string().required("This is Required"),
  owner_name: yup.string().required("This is Required"),
  owner_phone: yup
    .string()
    .matches(phoneRegExp, { message: "Not a valid Phone Number" })
    .required("This is Required"),
  // is_contact_person_same: yup.string().required("This is Required"),
  contact_person_name: yup.string().required("Must enter Contact Person Name"),
  contact_person_phone: yup.string().required("Must enter Contact Person Mobile No."),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("This is Required"),
  address: yup.string().required("This is Required"),
  state: yup.string().required("This is Required"),
  city: yup.string().required("This is Required"),
  pin_code: yup.string().min(6).max(6).required("This is Required"),
  // is_gst_registered: yup.string().required("This is Required"),
  // gst_no: yup.string().when("is_gst_registered", {
  //   is: "Yes",
  //   then: yup.string().required("Must enter Gst No."),
  // }),
  // pan_no: yup.string().min(10).max(10).required("This is Required"),
  // adhaar_no: yup.string().min(12).max(12),
  // is_msme_registered: yup.string().required("This is Required"),
  // msme_no: yup.string().when("is_msme_registered", {
  //   is: "Yes",
  //   then: yup.string().required("Must enter MSME No."),
  // }),
  // rera_no: yup.string().required("This is Required"),
  beneficiary_name: yup.string().required("This is Required"),
  account_no: yup.string().required("This is Required"),
  ifsc_code: yup.string().required("This is Required"),
  bank_name: yup.string().required("This is Required"),
  branch_name: yup.string().required("This is Required"),
  // gst_upload: yup.string().when("is_gst_registered", {
  //   is: "Yes",
  //   then: yup.string().required("You need to provide a file")
  //   }), 
  // pan_upload: yup.string().when("pan_no", {
  //   is: true,
  //   then: yup.string().required("You need to provide a file")
  //   }),
  // gst_upload: yup.string().required("You need to provide a file"),
  // pan_upload: yup.string().required("You need to provide a file"),  
  // adhaar_upload: yup
  //   .mixed()
  //   .test(
  //     "fileSize",
  //     "The file is too large",
  //     (value) => !value || (value && value.size <= 1024 * 1024 * 5)
  //   ),
  // msme_upload: yup.string().when("is_msme_registered", {
  //   is: "Yes",
  //   then: yup.string().required("You need to provide a file")
  //   }),
  // msme_upload: yup.string().required("You need to provide a file"), 
  // rera_upload: yup.string().required("You need to provide a file"),
  // cheque_upload: yup.string().required("You need to provide a file")
});

// yup
//       .mixed()
//       .required("You need to provide a file")
//       .test(
//         "fileSize",
//         "The file is too large",
//         (value) => !value || (value && value.size <= 1024 * 1024)
//       ),

const onSubmit = async (values, actions) => {
  console.log(values);
  console.log(actions);
  await new Promise((resolve) => setTimeout(resolve, 1000));
  // actions.resetForm();
};

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const CustomButton = ({ field, form, ...props }) => {
  return (
    <button className="registrationbutton" {...field} {...props} >
      {props.children}
    </button>
  );
};

function EmpanelmentForm() {
  let [isloading, setIsloading] = useState(false);
  const [initialValue, setInitialValue] = useState({})
  const navigate = useNavigate();
  let {id} = useParams()

  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('USER'))
  }catch(err){
    authToken = null
  }

  useEffect(() => {
    fetchDataById()

    return () => {
      
    }
  }, [])
  console.log(String(initialValue.org_name))

  const handleFileUpload = async(fileInput,fileUrl) => {
    console.log(fileInput,fileUrl)
    var formdata = new FormData();
    formdata.append("file", fileInput,fileUrl+fileInput.name );
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    const response = await fetch(process.env.REACT_APP_BASE_URL+"/api/vendor-registration/file-upload", requestOptions)
    const res = await response.json()
    return res.location
  }

  const fetchDataById = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/api/vendor-registration/"+id, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setInitialValue(result.channel_partner[0])
        // setRows(result.channel_partner)
      })
      .catch(error => console.log('error', error));
  }

  const multiFileUpload = async(org_name,uploadArray,values) => {
    let resArr = []
    const upload = await Promise.all(uploadArray.map(async(item) => {
      let nameString = org_name+item
      if (values[item]){
        const res = await handleFileUpload(values[item],nameString)
        resArr.push([item,res])
        return [item,res]
      }
    }))
    return [upload,resArr]
  }

  const handleSubmit = async (values, actions, errors) => {
    setIsloading(true)
    console.log("hello")
    console.log(values, actions, errors);

    let uploadArray = [
      "gst_upload",
      "pan_upload",
      "adhaar_upload",
      "msme_upload",
      "rera_upload",
      "cheque_upload",
    ]

    let uploadValues = {}
  
    // const arr = await multiFileUpload(values.org_name,uploadArray,values)
    // console.log(arr[1],"helllo")
    // await arr[1].map(item => {
    //   uploadValues[item[0].split("_")[0]+"_uri"] = item[1]
    // })
    // let mod = arr[1]
    // console.log(uploadValues,"moda")
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer "+authToken.token);

    var raw = JSON.stringify({
      org_name: values.org_name,
      type_of_business: values.type_of_business,
      owner_name: values.owner_name,
      owner_phone: values.owner_phone,
      contact_person_name: values.contact_person_name,
      contact_person_phone: values.contact_person_phone,
      message: values.message,
      email: values.email,
      address: values.address,
      state: values.state,
      city: values.city,
      pin_code: values.pin_code,
      beneficiary_name: values.beneficiary_name,
      account_no: values.account_no,
      ifsc_code: values.ifsc_code,
      bank_name: values.bank_name,
      branch_name: values.branch_name,
      rera_no: initialValue.rera_no
    });

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/api/vendor-registration/"+id, requestOptions)
      .then(result => {
        console.log(result.status)
        if (result.status%400<10){
          NotificationManager.error('Not authorised to edit the data', 'Error', 6000);
        }
        setIsloading(false)
        navigate("/admin/vendor")
      })
      .catch(error => {
        console.log('error', error)
        NotificationManager.error('Something went wrong', 'Error', 6000);
        setIsloading(false)
      });
    
  };

  return (
    <div style={{ padding: "1rem", width:"95%", minWidth:"360px" }}>
      {/* <div style={{background:"#161617", marginBottom:"1rem", color:"white", borderRadius:"5px", alignContent:"center", alignItems:"center",textAlign:"center"}}>
        <img src={hoabl_logo} width="150px" />
        <h2 style={{color:"#FFFFFF"}}> Channel Partner Registration Form</h2>
      </div> */}
      {initialValue?.org_name && (
        <Formik
        initialValues={{...initialValue}}
        validationSchema={vendorSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, values, submitCount, setFieldValue, handleSubmit,submitForm }) => (
          <Form  >
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                {/* <div style={{ padding: "1rem" }}> */}
                  <h4 style={{ font: "Jost" }}>Channel Partner Information</h4>
                {/* </div> */}
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  name="org_name"
                  label="Organisation Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={3} xs={6}>
                <div style={{ padding: "1rem" }}>
                  <p>Type of Business:</p>
                </div>
              </Grid>
              <Grid item md={3} xs={6}>
                <Field
                  as="select"
                  label="Type of Business"
                  name="type_of_business"
                  className="select"
                  style={
                    (touched?.type_of_business && errors?.type_of_business) ||
                    (errors?.type_of_business && submitCount)
                      ? {
                          borderColor: "rgb(211,47,47)",
                          color: "rgb(211,47,47)",
                        }
                      : {}
                  }
                >
                  <option value="">Choose...</option>
                  <option value="Domestic Company">Domestic Company</option>
                  <option value="Partnership">Partnership</option>
                  <option value="Proprietorship">Proprietorship</option>
                  <option value="Individual">Individual</option>
                  <option value="LLP">LLP</option>
                  <option value="HUF">HUF</option>
                  <option value="Trust">Trust</option>
                  <option value="AOP">AOP</option>
                  <option value="BOI">BOI</option>
                </Field>
                {touched?.type_of_business && errors?.type_of_business && (
                  <p className="error">{errors?.type_of_business}</p>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="owner_name"
                  label="Owner Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="owner_phone"
                  label="Contact No."
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={6} xs={8}>
                <div style={{ padding: "1rem" }}>
                  <p>Will the Owner handle the Channel Portal Administration?:</p>
                </div>
              </Grid>
              <Grid item md={6} xs={4}>
                <Field
                  as="select"
                  label="Is Contact Person Same"
                  name="is_contact_person_same"
                  className="select"
                  style={
                    (touched?.is_contact_person_same &&
                      errors?.is_contact_person_same) ||
                    (errors?.is_contact_person_same && submitCount)
                      ? {
                          borderColor: "rgb(211,47,47)",
                          color: "rgb(211,47,47)",
                        }
                      : {}
                  }
                >
                  <option value="">Choose...</option>
                  <option value="Yes">YES</option>
                  <option value="No">NO</option>
                </Field>
                {touched?.is_contact_person_same && errors?.is_contact_person_same && (
                  <p className="error">{errors?.is_contact_person_same}</p>
                )}
              </Grid> */}
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="contact_person_name"
                  label="Contact Person Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="contact_person_phone"
                  label="Contact Person Mobile No."
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="email"
                  name="email"
                  label="Email"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="pin_code"
                  label="Pin Code"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <p style={{ margin: "0 0 0 1rem" }}>Address:</p>
                <Field
                  as="textarea"
                  name="address"
                  label="Address"
                  style={
                    (touched?.address && errors?.address) ||
                    (errors?.address && submitCount)
                      ? {
                          borderColor: "rgb(211,47,47)",
                          color: "rgb(211,47,47)",
                        }
                      : {}
                  }
                />
                {touched?.address && errors?.address && (
                  <p className="error">{errors?.address}</p>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="state"
                  label="State"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="city"
                  label="City"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              {/* Documentaion Comes Here */}
              <Grid item md={12} xs={12}>
                <div className="divider"></div>
              </Grid>
              <Grid item md={12} xs={12}>
                <h4 style={{ font: "Jost" }}>Bank Details</h4>
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="beneficiary_name"
                  label="Beneficiary Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="account_no"
                  label="Account No."
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="ifsc_code"
                  label="IFSC Code"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="bank_name"
                  label="Bank Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Field
                  type="text"
                  name="branch_name"
                  label="Branch Name"
                  fullWidth
                  component={TextInput}
                />
              </Grid>
              {/* <Grid item md={5} xs={10}>
                <div style={{ padding: "1rem" }}>
                  <p>Upload Cancelled Cheque/ Passbook 1st Page:</p>
                  {errors?.cheque_upload && (
                    <p className="error">{errors?.cheque_upload}</p>
                  )}
                </div>
              </Grid>
              <Grid item md={1} xs={2}>
                <div style={{ padding: "1rem 0 1rem 0" }}>
                  <label for="cheque_upload">
                    <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    id="cheque_upload"
                    name="cheque_upload"
                    onChange={(event) => {
                      setFieldValue(
                        "cheque_upload",
                        event.currentTarget.files[0]
                      );
                    }}
                    hidden
                  />
                </div>
              </Grid> */}
              <Grid item md={12} xs={12}>
                <Field id="submitEdit" name="submitEdit" type="submit"  component={CustomButton}>
                    {isloading ? <CircularProgress sx={{color:"#FFFFFF"}} size="1.5rem"/>:"Submit"}
                </Field>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      )}
    </div>
  );
}

export default EmpanelmentForm;






// {/* <Grid item md={12} xs={12}>
//                 <div className="divider"></div>
//               </Grid>
//               <Grid item md={12} xs={12}>
//                 {/* <div style={{ padding: "1rem" }}> */}
//                   <h4 style={{ font: "Jost" }}>Documentation</h4>
//                 {/* </div> */}
//               </Grid>
//               <Grid item md={3} xs={6}>
//                 <div style={{ padding: "1rem" }}>
//                   <p>Is Gst Registered:</p>
//                 </div>
//               </Grid>
//               <Grid item md={3} xs={6}>
//                 <Field
//                   as="select"
//                   label="Is Gst Registered"
//                   name="is_gst_registered"
//                   className="select"
//                   style={
//                     (touched?.is_gst_registered && errors?.is_gst_registered) ||
//                     (errors?.is_gst_registered && submitCount)
//                       ? {
//                           borderColor: "rgb(211,47,47)",
//                           color: "rgb(211,47,47)",
//                         }
//                       : {}
//                   }
//                 >
//                   <option value="">Choose...</option>
//                   <option value="Yes">YES</option>
//                   <option value="No">NO</option>
//                 </Field>
//                 {touched?.is_gst_registered && errors?.is_gst_registered && (
//                   <p className="error">{errors?.is_gst_registered}</p>
//                 )}
//               </Grid>
//               <Grid item md={5} xs={10}>
//                 {values?.is_gst_registered === "Yes" && (
//                   <>
//                     <Field
//                       type="text"
//                       name="gst_no"
//                       label="GST No."
//                       fullWidth
//                       component={TextInput}
//                     />
//                     {errors?.gst_upload && (
//                       <p className="error">{errors?.gst_upload}</p>
//                     )}
//                   </>
//                 )}
//               </Grid>
//               <Grid item md={1} xs={2}>
//                 {values?.is_gst_registered === "Yes" && (
//                   <div style={{ padding: "1rem 0 1rem 0" }}>
//                     <label for="gst_upload">
//                       <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
//                     </label>
//                     <input
//                       type="file"
//                       accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
//                       id="gst_upload"
//                       name="gst_upload"
//                       onChange={(event) => {
//                         setFieldValue(
//                           "gst_upload",
//                           event.currentTarget.files[0]
//                         );
//                       }}
//                       hidden
//                     />
//                   </div>
//                 )}
//               </Grid>
//               <Grid item md={5} xs={10}>
//                 <Field
//                   type="text"
//                   name="pan_no"
//                   label="PAN No."
//                   fullWidth
//                   component={TextInput}
//                 />
//                 {touched?.pan_no && errors?.pan_upload && (
//                   <p className="error">{errors?.pan_upload}</p>
//                 )}
//               </Grid>
//               <Grid item md={1} xs={2}>
//                 <div style={{ padding: "1rem 0 1rem 0" }}>
//                   <label for="pan_upload">
//                     <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
//                   </label>
//                   <input
//                     type="file"
//                     accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
//                     id="pan_upload"
//                     name="pan_upload"
//                     onChange={(event) => {
//                       setFieldValue("pan_upload", event.currentTarget.files[0]);
//                     }}
//                     hidden
//                   />
//                 </div>
//               </Grid>
//               <Grid item md={5} xs={10}>
//                 <Field
//                   type="text"
//                   name="adhaar_no"
//                   label="Adhaar No."
//                   fullWidth
//                   component={TextInput}
//                 />
//                 {touched?.adhaar_no && errors?.adhaar_upload && (
//                   <p className="error">{errors?.adhaar_upload}</p>
//                 )}
//               </Grid>
//               <Grid item md={1} xs={2}>
//                 <div style={{ padding: "1rem 0 1rem 0" }}>
//                   <label for="adhaar_upload">
//                     <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}></span>
//                   </label>
//                   <input
//                     type="file"
//                     accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
//                     id="adhaar_upload"
//                     name="adhaar_upload"
//                     onChange={(event) => {
//                       setFieldValue(
//                         "adhaar_upload",
//                         event.currentTarget.files[0]
//                       );
//                     }}
//                     hidden
//                   />
//                 </div>
//               </Grid>
//               <Grid item md={3} xs={6}>
//                 <div style={{ padding: "1rem" }}>
//                   <p>Is MSME Registered:</p>
//                 </div>
//               </Grid>
//               <Grid item md={3} xs={6}>
//                 <Field
//                   as="select"
//                   label="Is MSME Registered"
//                   name="is_msme_registered"
//                   className="select"
//                   style={
//                     (touched?.is_msme_registered &&
//                       errors?.is_msme_registered) ||
//                     (errors?.is_msme_registered && submitCount)
//                       ? {
//                           borderColor: "rgb(211,47,47)",
//                           color: "rgb(211,47,47)",
//                         }
//                       : {}
//                   }
//                 >
//                   <option value="">Choose...</option>
//                   <option value="Yes">YES</option>
//                   <option value="No">NO</option>
//                 </Field>
//                 {touched?.is_msme_registered && errors?.is_msme_registered && (
//                   <p className="error">{errors?.is_msme_registered}</p>
//                 )}
//               </Grid>
//               <Grid item md={5} xs={10}>
//                 {values?.is_msme_registered === "Yes" && (
//                   <>
//                     <Field
//                       type="text"
//                       name="msme_no"
//                       label="MSME No."
//                       fullWidth
//                       component={TextInput}
//                     />
//                     {errors?.msme_upload && (
//                       <p className="error">{errors?.msme_upload}</p>
//                     )}
//                   </>
//                 )}
//               </Grid>
//               <Grid item md={1} xs={2}>
//                 {values?.is_msme_registered === "Yes" && (
//                   <div style={{ padding: "1rem 0 1rem 0" }}>
//                     <label for="msme_upload">
//                       <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
//                     </label>
//                     <input
//                       type="file"
//                       accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
//                       id="msme_upload"
//                       name="msme_upload"
//                       onChange={(event) => {
//                         setFieldValue(
//                           "msme_upload",
//                           event.currentTarget.files[0]
//                         );
//                       }}
//                       hidden
//                     />
//                   </div>
//                 )}
//               </Grid>
//               <Grid item md={5} xs={10}>
//                 <Field
//                   type="text"
//                   name="rera_no"
//                   label="RERA No."
//                   fullWidth
//                   component={TextInput}
//                 />
//                 {touched?.rera_no && errors?.rera_upload && (
//                   <p className="error">{errors?.rera_upload}</p>
//                 )}
//               </Grid>
//               <Grid item md={1} xs={2}>
//                 <div style={{ padding: "1rem 0 1rem 0" }}>
//                   <label for="rera_upload">
//                     <CloudUploadIcon /><span style={{color:"rgb(211,47,47)"}}>*</span>
//                   </label>
//                   <input
//                     type="file"
//                     accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
//                     id="rera_upload"
//                     name="rera_upload"
//                     onChange={(event) => {
//                       setFieldValue(
//                         "rera_upload",
//                         event.currentTarget.files[0]
//                       );
//                     }}
//                     hidden
//                   />
//                 </div>
//               </Grid> */}
