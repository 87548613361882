import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ImageIcon from '@mui/icons-material/Image';
import { Navigate } from "react-router-dom";
import ImageModal from './ImageModal';
import { Button, TextField } from '@mui/material';
import { Link } from 'react-router-dom'
import {NotificationContainer, NotificationManager} from 'react-notifications';

const columns = [
  // { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'hoabl_id', label: 'Hoabl_id', minWidth: 100 },
  { id: 'org_name', label: 'Organisation Name', minWidth: 170 },
  { id: 'type_of_business', label: 'Type of Business', minWidth: 170 },
  { id: 'owner_name', label: 'Owner Name', minWidth: 170 },
  { id: 'owner_phone', label: 'Owner Phone', minWidth: 170 },
  { id: 'contact_person_name', label: 'Contact Person Name', minWidth: 170 },
  { id: 'contact_person_phone', label: 'Contact Person Phone', minWidth: 170 },
  { id: 'email', label: 'Email', minWidth: 170 },
  { id: 'address', label: 'Address', minWidth: 170 },
  { id: 'state', label: 'State', minWidth: 170 },
  { id: 'city', label: 'City', minWidth: 170 },
  { id: 'is_gst_registered', label: 'Is_gst_registered', minWidth: 170 },
  { id: 'gst_no', label: 'Gst_no', minWidth: 170 },
  { id: 'pan_no', label: 'Pan_no', minWidth: 170 },
  { id: 'adhaar_no', label: 'Adhaar_no', minWidth: 170 },
  { id: 'is_msme_registered', label: 'Is_msme_registered', minWidth: 170 },
  { id: 'msme_no', label: 'Msme_no', minWidth: 170 },
  { id: 'rera_no', label: 'Rera_no', minWidth: 170 },
  { id: 'beneficiary_name', label: 'Beneficiary_name', minWidth: 170 },
  { id: 'account_no', label: 'Account_no', minWidth: 170 },
  { id: 'ifsc_code', label: 'Ifsc_code', minWidth: 170 },
  { id: 'bank_name', label: 'Bank_name', minWidth: 170 },
  { id: 'branch_name', label: 'Branch_name', minWidth: 170 },
  { id: 'gst_uri', label: 'Gst_uri', minWidth: 150 },
  { id: 'msme_uri', label: 'Msme_uri', minWidth: 150 },
  { id: 'pan_uri', label: 'Pan_uri', minWidth: 150 },
  { id: 'adhaar_uri', label: 'Adhaar_uri', minWidth: 150 },
  { id: 'rera_uri', label: 'Rera_uri', minWidth: 150 },
  { id: 'cheque_uri', label: 'Cheque_uri', minWidth: 150 },
  { id: 'action', label: 'Action', minWidth: 150 },
];


// "https://feedback-app.hoabl.in/api/vendor-registration"

function DataList() {
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  let authToken;
  try{
    authToken = JSON.parse(localStorage.getItem('USER'))
  }catch(err){
    authToken = null
  }
  
  React.useEffect(() => {
    
    handleDataCall()

  }, [])
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleDataCall = () => {
    // let authToken = JSON.parse(localStorage.getItem('USER'))
    // console.log(authToken)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer" +" "+ authToken.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"/api/vendor-registration", requestOptions)
      .then(response => {
        console.log(response.status)
        if (response.status%400<10){
          NotificationManager.error('Not authorised to view this data', 'Error', 6000);
          throw new Error('Unauthorised role!!');
        }
        return response.json()
      })
      .then(result => {
        // console.log(result.status)
        console.log(JSON.stringify(result.channel_partner))
        setData(result.channel_partner)
        setRows(result.channel_partner)
      })
      .catch(error => console.log('error', error));
  }

  const handleSearch = (e) => {
    console.log(e.target.value,data)
    setPage(0)
    setRows(() => {
      return data.filter((item) => (item.org_name+item.hoabl_id+item.owner_name).toLowerCase().includes(e.target.value.toLowerCase()))
    })
  }

  return (
    <div style={{padding:"1rem 1rem"}}>
      <div style={{padding:"0 0 1rem 0"}}>
        <TextField label="Search" onChange={handleSearch} style={{width:"250px"}} />
      </div>
      <TableContainer sx={{ maxHeight: '80vh' }} >
        <Table stickyHeader aria-label="sticky table" >
          <TableHead >
            <TableRow >
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor:"grey"}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      
                      if (column.label === 'Action'){
                        console.log(row['hoabl_id'])
                        return(
                          <TableCell key={column.id} align={column.align}>
                            <Link to={`edit/${row['hoabl_id']}`} style={{all:'unset'}}>
                              <Button>Edit</Button>
                            </Link>
                          </TableCell>
                        )
                      }
                      if (column.label.includes("_uri")){
                        return(
                          <TableCell key={column.id} align={column.align}>
                            {value && <ImageModal url={value}/>}
                          </TableCell>
                        )
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  )
}

export default DataList