import React,{ useEffect } from 'react'
import hoabl_logo from "../../assets/the house of abhinandan lodha logo-02.png"
import { useNavigate } from "react-router-dom"
import backgroundImg from '../../assets/pexels-lucas-pezeta-2423412_low.jpeg'

function Thanks() {
  const navigate = useNavigate();
  
  return (
    <div className="cover-image" style={{ padding: "1rem", height:'100vh', width:"100%",minWidth:"360px", display:'flex', justifyContent:'center', alignContent:"center", alignItems:"center" }}>
      <div style={{ border:'1px solid #FFFFFF', width:"60%", minWidth:'340px', padding:"1rem", marginBottom:"1rem", color:"white", borderRadius:"5px", alignContent:"center", alignItems:"center",textAlign:"center"}}>
        <img src={hoabl_logo} width="200px" />
        <h1 style={{color:"#FFFFFF", fontFamily:"Jost"}}>Thank You!!!</h1>
        <h3 style={{color:"#FFFFFF", fontFamily:"Jost"}}>Your Onboarding is completed successfully.</h3>
      </div>
    </div>
  )
}

export default Thanks