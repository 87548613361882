import React,{useState} from 'react'
import ReactDom from 'react-dom'
import ImageIcon from '@mui/icons-material/Image';
 
const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#FFF',
  padding: '50px',
  zIndex: 1000,
  width: 'fit-content',
  maxWidth: '80vw',
}

const OVERLAY_STYLES = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000,
}

export default function ImageModal({ children, url}) {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  console.log(url)

  if (!open) return <ImageIcon onClick={() => setOpen(true)}/>

  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={handleClose}/>
      <div style={MODAL_STYLES}>
        {
          url.includes(".pdf") ? (
            <p>This is PDF file <a href={url} target="_blank">Click here to view pdf</a>.</p>
          ) : (
            <img src={url} style={{maxWidth:'80vw',maxHeight:'75vh'}} />
          )
        }
        
      </div>
    </>,
    document.getElementById('portal')
  )
}