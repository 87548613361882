import React,{ useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import hoabl_black from '../../assets/hoabl_black.png'
import "bootstrap/dist/css/bootstrap.min.css"


axios.defaults.withCredentials = true;

const styles = {
  authFormContainer: {
    display:' flex',
    justifyContent:' center',
    alignItems:' center',
    width:' 100vw',
    height:' 100vh',
    flexDirection:'column',
  },
  authForm: {
    width:' 420px',
    boxShadow:' rgb(0 0 0 / 16%) 1px 1px 10px',
    paddingTop:' 30px',
    paddingBottom:' 20px',
    borderRadius:' 8px',
    backgroundColor:' white',
  },
  authFormContent:{
    paddingLeft:' 12%',
    paddingRight:' 12%',
    alignItems:"center",
    
  },
  authFormTitle:{
    textAlign:' center',
    marginBottom:' 1em',
    fontSize:' 24px',
    color:' rgb(34, 34, 34)',
    fontWeight:' 800',
  },
  label:{
    fontSize:' 14px',
    fontWeight:' 600',
    color:' rgb(34, 34, 34)',
  },
}

function Login() {
  let [authMode, setAuthMode] = useState("signin")
  const [values, setValues] = useState({
    name: "",
    email: "",
    department: "",
    password: "",   
  })
  const navigate = useNavigate();

  const handleValuesChange = (e) => {
    setValues(prev => ({...prev,[e.target.name]:e.target.value }))
  }

  const changeAuthMode = () => {
    setAuthMode(authMode === "signin" ? "signup" : "signin")
  }
  
  // debugger
  const handleLogin = (e) => {
    e.preventDefault()

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Access-Control-Allow-Credentials",true);

    var data = JSON.stringify({
      "email": values.email,
      "password": values.password
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: data,
      // credentials: 'include',
      // node:'cors',
      redirect: 'follow'
    };
    
    fetch(process.env.REACT_APP_BASE_URL+"/api/auth/signin", requestOptions)
      .then(response => {
        console.log(response.status)
        if(response.status === 200){
          return response.json()
        }
        throw response
      })
      .then(response => {
        console.log(response);
        localStorage.setItem('USER',JSON.stringify(response))
        setValues({
          name: "",
          email: "",
          department: "",
          password: "",   
        })
        navigate('/admin/vendor')
        
      })
      .catch(error => {
        error.json().then(data => {
          console.log(data)
          NotificationManager.error(data.message, 'Error', 6000);
        })
      });
    

  }
  
  const handleSignup = (e) => {
    e.preventDefault()

    var data = JSON.stringify({
      "name": values.name,
      "email": values.email,
      "password": values.password,
      "department": values.department
    });

    console.log(data)
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    // myHeaders.append("Access-Control-Allow-Credentials",true);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: data,
      // credentials: 'include',
      // node:'cors',
      redirect: 'follow'
    };
    
    fetch(process.env.REACT_APP_BASE_URL+"/api/auth/signup", requestOptions)
      .then(response => {
        console.log(response.status)
        if(Math.floor(response.status/100)===2){
          return response.json()
        }
        throw response
      })
      .then(response => {
        console.log(response);
        setValues({
          name: "",
          email: "",
          department: "",
          password: "",   
        })
        setAuthMode('signin')
        
      })
      .catch(error => {
        error.json().then(data => {
          console.log(data)
          if (data.message?.keyPattern?.email > 0){
            NotificationManager.error('Email already exists', 'Error', 6000);
          }
          else{
            NotificationManager.error('Something went wrong', 'Error', 6000);
          }
          
        })
      });
  }

  if (authMode === "signin") {
    return (
      <div style={styles.authFormContainer}>
        <img src={hoabl_black} width="200px"/>
        <form style={styles.authForm} onSubmit={handleLogin}>
          <div style={styles.authFormContent}>
            <h3 style={styles.authFormTitle}>Sign In</h3>
            <div className="text-center">
              Not registered yet?{" "}
              <span className="link-primary" onClick={changeAuthMode}>
                Sign Up
              </span>
            </div>
            <div className="form-group mt-3">
              <label>Email address</label>
              <input
                name="email"
                type="email"
                value={values.email}
                className="form-control mt-1"
                placeholder="Enter email"
                onChange={handleValuesChange}
              />
            </div>
            <div className="form-group mt-3">
              <label>Password</label>
              <input
                name="password"
                type="password"
                value={values.password}
                className="form-control mt-1"
                placeholder="Enter password"
                onChange={handleValuesChange}
              />
            </div>
            <div className="d-grid gap-2 mt-3">
              <button  type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
            {/* <p className="text-center mt-2">
              Forgot <a href="#">password?</a>
            </p> */}
          </div>
        </form>
      </div>
    )
  }

  return (
    <div style={styles.authFormContainer}>
      <img src={hoabl_black} width="200px"/>
      <form style={styles.authForm} onSubmit={handleSignup}>
        <div style={styles.authFormContent}>
          <h3 style={styles.authFormTitle}>Sign Up</h3>
          <div className="text-center">
            Already registered?{" "}
            <span className="link-primary" onClick={changeAuthMode}>
              Sign In
            </span>
          </div>
          <div className="form-group mt-3">
            <label>Full Name</label>
            <input
              name="name"
              type="text"
              value={values.name}
              className="form-control mt-1"
              placeholder="e.g Jane Doe"
              onChange={handleValuesChange}
            />
          </div>
          <div className="form-group mt-3">
            <label>Department</label>
            <input
              name="department"
              type="text"
              value={values.department}
              className="form-control mt-1"
              placeholder="Technology"
              onChange={handleValuesChange}
            />
          </div>
          
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              name="email"
              type="email"
              value={values.email}
              className="form-control mt-1"
              placeholder="Email Address"
              onChange={handleValuesChange}
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              name="password"
              type="password"
              value={values.password}
              className="form-control mt-1"
              placeholder="Password"
              onChange={handleValuesChange}
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          {/* <p className="text-center mt-2">
            Forgot <a href="#">password?</a>
          </p> */}
        </div>
      </form>
    </div>
  )
}

export default Login