import React,{ useState } from 'react'
import {QRCodeSVG} from "qrcode.react";
import { TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid";

function QrCodeGenerator() {
  const [baseUrl, setBaseUrl] = useState("")
  const [source, setSource] = useState("")
  const [medium, setMedium] = useState("")
  const [campaign, setCampaign] = useState("")
  const [adgroup, setAdgroup] = useState("")
  const [term, setTerm] = useState("")
  const [content, setContent] = useState("")
  const [display, setDisplay] = useState("")
  const [fileName, setFileName] = useState("")
  const downloadQR = () => {
    const canvas = document.getElementById("123456");
    // console.log(canvas)
    const svgUrl = new XMLSerializer().serializeToString(canvas)
    var encodedData = "data:image/svg+xml;base64,"+window.btoa(svgUrl);
    // console.log(svgUrl,encodedData)
    let downloadLink = document.createElement("a");
    downloadLink.href = encodedData;
    downloadLink.download = `${fileName}.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <div style={{padding:'1rem'}}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <h5>No Spaces!!! Please use underscore "_" instead of spaces for utm params</h5>
        </Grid>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth 
            label="Base Url"
            helperText="E.g: https://hoabl.in/newspaper-capeofbliss/"
            value={baseUrl}
            onChange={(e) => setBaseUrl(e.target.value)} 
            />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth 
            label="Utm Source"
            helperText="E.g: in_shorts"
            value={source}
            onChange={(e) => setSource(e.target.value)} 
            />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth 
            label="Utm medium"
            helperText="E.g: digital"
            value={medium}
            onChange={(e) => setMedium(e.target.value)} 
            />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth 
            label="Utm Campaign"
            helperText="E.g: tomorrowview_inshorts"
            value={campaign}
            onChange={(e) => setCampaign(e.target.value)} 
            />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth 
            label="UTM Ad Group Name"
            helperText="E.g: JobProfiles-FB/IG-Delhi"
            value={adgroup}
            onChange={(e) => setAdgroup(e.target.value)} 
            />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth 
            label="UTM Term"
            helperText="E.g: {keyword} - plots in goa"
            value={term}
            onChange={(e) => setTerm(e.target.value)} 
            />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth 
            label="UTM Content"
            helperText="E.g: PT-FB-LP-Bicholim-Delhi-Mahurat"
            value={content}
            onChange={(e) => setContent(e.target.value)} 
            />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth 
            label="UTM Display"
            helperText="E.g: back-page-full-page"
            value={display}
            onChange={(e) => setDisplay(e.target.value)} 
            />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextField
            fullWidth 
            label="Qr File Name"
            helperText="E.g: TVInshorts"
            value={fileName}
            onChange={(e) => setFileName(e.target.value)} 
            />
        </Grid>
      </Grid>
      {baseUrl && (
        <div style={{margin:'1rem'}}>
          <a href={`${baseUrl}?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}&utm_adgroup=${adgroup}&utm_term=${term}&utm_content=${content}&utm_display=${display}`}>{`${baseUrl}?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}&utm_adgroup=${adgroup}&utm_term=${term}&utm_content=${content}&utm_display=${display}`}</a> <br/>
          <QRCodeSVG
            id="123456"
            value={`${baseUrl}?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}&utm_adgroup=${adgroup}&utm_term=${term}&utm_content=${content}&utm_display=${display}`}
            size={400}
            level={"H"}
            includeMargin={true}
          /><br/>
          <Button onClick={downloadQR}> Download QR </Button>
        </div>
      )}
    </div>
  )
}

export default QrCodeGenerator