// Basic.js
import { ErrorMessage, Field, Form, Formik, FieldArray } from "formik";
import React, { useContext, useState } from "react";
import { FormContext } from "../index";
import * as yup from "yup";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { choices } from "./choices";
import StarRatings from 'react-star-ratings';
import Stack from '@mui/material/Stack';
import MenuItem from "@mui/material/MenuItem";
import { handleSharepointFileUpload } from "../../utility/sharepointUpload";
 


const customerOnboarding3Schema = yup.object().shape({
  // hoabl_whatisyourfavoritepartofhoabl: yup.string(),
  // hoabl_rateusonascaleof1to5onyourexperiencewi: yup.number().min(1).max(5),
  hoabl_amessageforus: yup.string().max(1000,"Max Characters cannot exceed 100"),
  toggle: yup.string().required("Please add details"),
  hoabl_whatdidyoulikethemost: yup.string().max(100,"Max Characters cannot exceed 100").required("Please add details"),
  hoabl_howcanwemakeyourexperiencebetter: yup.string().max(1000,"Max Characters cannot exceed 100").required("Please add details"),
});

const styles = {
  labelColor: {
    color: "#7F7F7F",
  },
};

const TextInput = ({ field, form, ...props }) => {
  // if (field.name === "org_name") {
  //   console.log(field, form);
  // }

  return (
    <TextField
      {...field}
      {...props}
      error={
        (form?.touched[field?.name] && form?.errors[field?.name]) ||
        (form?.errors[field?.name] && form?.submitCount > 0)
      }
      helperText={form?.touched[field?.name] && form?.errors[field?.name]}
    />
  );
};

const CustomButton = (props) => {
  return (
    <button className="registrationbutton-co" {...props}>
      {props.children}
    </button>
  );
};

function FinalPage({data}) {
  const [isloading, setIsloading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [rating, setRating] = useState(0);
  const navigate = useNavigate();
  const {
    id,
    applicant_name,
    project_name,
    plot_no,
    plot_area,
    special_offers,
    mobile_no,
    contact_id,
    sharepoint_name
  } = data;
  // } = useParams();
  // console.log(sharepoint_name.split("_")[0])
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  const handleSubmit = async(values,errors) => {
    setSubmitClicked(true)
    // console.log(values,errors)
    // return
    if(values?.toggle !== true || !values?.hoabl_amessageforus){
      setErrorMessage("All the fields are mandatory and please check the confirmation checkbox true")
      return
    }

    const sharepointUrlParams = {
      contactId: contact_id.replace(/-/g, ""),
      full_name: sharepoint_name.split("_")[0],
      sharepoint_folder_name: sharepoint_name,
      plotno: plot_no.trim(),
      bookingId: id.replace(/-/g, ""),
    }

    handleSharepointFileUpload(formData['hoabl_aadhaarcard'],formData['hoabl_aadhaarcard_name'],sharepointUrlParams)
    handleSharepointFileUpload(formData['hoabl_pancard'],formData['hoabl_pancard_name'],sharepointUrlParams)

    setIsloading(true);
    // console.log(formData["hoabl_phonecodeprimaryapplicant"])
    let upload_arr = [] 
    formData["hoabl_primaryapplicantmobilenumber"] = String(formData["hoabl_primaryapplicantmobilenumber"]?.slice(formData.hoabl_phonecodeprimaryapplicant?.length-1))
    formData["hoabl_alternatemobilenoprimaryapplicant"] = String(formData["hoabl_alternatemobilenoprimaryapplicant"]?.slice(formData.hoabl_phonecodealternate?.length-1))
    formData["hoabl_rateusonascaleof1to5onyourexperiencewi"] = String(rating)
    formData["hoabl_whatisyourfavoritepartofhoabl"] = String(values?.hoabl_whatisyourfavoritepartofhoabl)
    formData["hoabl_amessageforus"] = String(values?.hoabl_amessageforus)
    formData["hoabl_whatdidyoulikethemost"] = String(values?.hoabl_whatdidyoulikethemost)
    formData["hoabl_howcanwemakeyourexperiencebetter"] = String(values?.hoabl_howcanwemakeyourexperiencebetter)
    formData["hoabl_noofchildrenifapplicable"] = String(formData["hoabl_noofchildrenifapplicable"])
    formData?.co_applicants?.map((item,index) => {
      formData[`hoabl_namecoapplicant${index+1}`] = item.hoabl_namecoapplicant
      formData[`hoabl_pannocoapplicant${index+1}`] = item.hoabl_pannocoapplicant
      formData[`hoabl_aadhaarnocoapplicant${index+1}`] = item.hoabl_aadhaarnocoapplicant
      formData[`hoabl_coapplicant${index+1}mobilenumber`] = item.hoabl_coapplicantmobilenumber?.slice(formData[`hoabl_phonecodecoapplicant${index+1}`]?.length-1)
      formData[`hoabl_coapplicant${index+1}profession`] = item.hoabl_coapplicantprofession
      formData[`hoabl_coapplicant${index+1}nameoftheorganization`] = item.hoabl_coapplicantnameoftheorganization
      formData[`hoabl_coapplicant${index+1}designation`] = item.hoabl_coapplicantdesignation
      formData[`hoabl_dobcoapplicant${index+1}`] = item.hoabl_dobcoapplicant
      formData[`hoabl_salutationcoapplicant${index+1}`] = item.hoabl_salutationcoapplicant
      formData[`hoabl_relationshipwithcoapplicant${index+1}`] = item.hoabl_relationshipwithcoapplicant
      let upload_key_pan = `hoabl_coapplicant${index+1}pan_upload`
      let upload_key_aadhaar = `hoabl_coapplicant${index+1}aadhaar_upload`
      item.hoabl_coapplicantaadhaar_upload && handleSharepointFileUpload(item.hoabl_coapplicantaadhaar_upload,item.hoabl_coapplicantaadhaar_upload_name,sharepointUrlParams)
      item.hoabl_coapplicantpan_upload && handleSharepointFileUpload(item.hoabl_coapplicantpan_upload,item.hoabl_coapplicantpan_upload_name,sharepointUrlParams)
    } )
    formData?.childrens?.map((item,index) => {
      if(index === 0) {
        formData[`hoabl_nameofyourchild${index+1}ifapplicable`] = item.hoabl_nameofyourchildifapplicable
        formData[`hoabl_whencanwewishyourchild`] = item.hoabl_whencanwewishyourchild
      }else {
        formData[`hoabl_nameofyourchild${index+1}ifapplicable`] = item.hoabl_nameofyourchildifapplicable
        formData[`hoabl_whencanwewishyourchild${index+1}`] = item.hoabl_whencanwewishyourchild
      }
      
      
    } )
    // let mod = arr[1]

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    delete formData['childrens'];
    delete formData['co_applicants'];
    delete formData['hoabl_aadhaarcard'];
    delete formData['hoabl_pancard'];

    // sharepointUrlParams.bookingId

    var raw = JSON.stringify({
      hoabl_hoabl_customeronboarding_Booking_quote: id,
      ...formData,
    }); 

    // raw.hoabl_aadhaarcard = adhaarBinary

    // var raw = {
    //   hoabl_hoabl_customeronboarding_Booking_quote: id,
    //   ...formData,
    // }
    // var raw = new FormData();
    // var keysArray = Object.keys(formData)
    // raw.append("hoabl_hoabl_customeronboarding_Booking_quote",id)
    // keysArray.map(item => {
    //   raw.append(item, formData[item])
    // })

    

    // console.log(
    //   JSON.stringify({
    //     hoabl_hoabl_customeronboarding_Booking_quote: id,
    //     ...formData,
    //   })
    // );
    // console.log(formData)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      cors: "no-cors",
      redirect: "follow",
    };

    fetch(
      "https://prod-06.centralindia.logic.azure.com:443/workflows/08f600368b4a44c6a3f6e45e860405bb/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Kvqlo4d2swW9ItQ6idIvysd1fSDovpy5CeapR2u0mEA",
      requestOptions
    )
      .then((result) => {
        if (result.status === 400) {
          setIsloading(false);
          navigate("/error");
        }
        // console.log(result);
        setIsloading(false);
        navigate("/customer-onboarding/thanks");
      })
      .catch((error) => {
        navigate("/error");
        console.log("error", error);
        setIsloading(false);
      });
    setIsloading(false);
  };

  return (
    <div style={{background:'rgb(255,255,255)', padding: "1rem", borderRadius:'10px'  }}>
      <Formik
        initialValues={{
          ...formData,
        }}
        validationSchema={customerOnboarding3Schema}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          // setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        {({ touched, errors, values, submitCount, setFieldValue }) => (
          <Grid container align="center" spacing={2} sx={{ padding: "2rem 0 0 0" }}>
            <Grid item md={12} xs={12}>
                {/* <div style={{ padding: "1rem" }}> */}
                <h4 style={{ font: "Jost", fontWeight:'bold' }}>Share Your Feedback</h4>
                {/* </div> */}
              </Grid>

            <Grid item md={3} xs={false}></Grid>
            <Grid item md={6} xs={12}>
              <Stack  spacing={2}>
              
              <StarRatings
                rating={rating}
                // starRatedColor="blue"
                changeRating={(newRating, name) => setRating(newRating)}
                numberOfStars={5}
                name='rating'
                starRatedColor={"#75732E"}
                starEmptyColor={"lightgrey"}
                starHoverColor={"#FECF40"}
              />
              <Grid item md={12} xs={12}>
                <p><span style={{margin:'1rem'}}>
                <Field
                  type="checkbox"
                  name="toggle"
                  style={{transform:"scale(1.5)"}}
                />
                </span> I/We hereby confirm that the details provided by me are true and correct and the said details should be incorporated in my Agreement.*</p>
                
              </Grid>
              {rating >= 4 && (
                <>
                  <Field
                    name="hoabl_whatdidyoulikethemost"
                    label="What did you like the most?*:"
                    fullWidth
                    select={true}
                    SelectProps={{
                      onClose: () => {
                        setTimeout(() => {
                          document.activeElement.blur();
                        }, 0);
                      },
                    }}
                    component={TextInput}
                    style={{textAlign:'left'}}
                  >
                    {choices?.hoabl_whatdidyoulikethemost?.map((item) => (
                      <MenuItem key={item.id} value={item.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Field>
                </>
              )}
              {rating !==0 && rating < 4 && (
                <>
                  <Field
                    name="hoabl_howcanwemakeyourexperiencebetter"
                    label="How can we make your experience better?"
                    fullWidth
                    component={TextInput}
                  />
                </>
              )}
              <Field
                name="hoabl_amessageforus"
                label="A message for us"
                fullWidth
                component={TextInput}
              />
              </Stack>
            </Grid>
            {/* <Grid item md={9} xs={9}>
              <p>Hello</p>
            </Grid> */}
            <Grid item md={3} xs={false}></Grid>
            
            <Grid item md={12} xs={12}>
              {submitClicked && (
                <p className="error">{errorMessage}</p>
              )}
            </Grid>
            
            <Grid item md={8} sm={5} xs={3}></Grid>
            <Grid item md={2} sm={3} xs={4}>
              <CustomButton
                name="back"
                onClick={() => setActiveStepIndex((prev) => prev - 1)}
              >
                <span style={{fontWeight:'bold'}} >BACK</span>
              </CustomButton>
            </Grid>
            <Grid item md={2} sm={3} xs={4}>
              <CustomButton name="submit" onClick={()=>handleSubmit(values,errors)}>
                {isloading ? (
                  <CircularProgress sx={{ color: "#FFFFFF" }} size="1.5rem" />
                ) : (
                  <span style={{fontWeight:'bold'}} >SUBMIT</span>
                )}
              </CustomButton>
            </Grid>
          </Grid>
        )}
      </Formik>
    </div>
  );
}

export default FinalPage;






// hoabl_namecoapplicant2: yup.string(),
// hoabl_pannocoapplicant2: yup.string(),
// hoabl_coapplicant2mobilenumber: yup.string(),
// hoabl_coapplicant2profession: yup.string(),
// hoabl_coapplicant2nameoftheorganization: yup.string(),
// hoabl_coapplicant2designation: yup.string(),

{/* <Grid item md={6} xs={12}>
<Field
  name="hoabl_rateusonascaleof1to5onyourexperiencewi"
  label="Rate us on a scale of 1 to 5 on your experience with us"
  fullWidth
  component={TextInput}
/>
</Grid> */}


