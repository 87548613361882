import React,{ useEffect } from 'react'
import hoabl_logo from "../../assets/the house of abhinandan lodha logo-02.png"
import {useNavigate} from "react-router-dom"

function SubmitLead() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "https://forms.hoabl.in/lead-demo"
    },20000)
  
  }, [])
  

  return (
    <div style={{ padding: "1rem", width:"80%",minWidth:"360px"}}>
      <div style={{background:"#161617", width:"100%", padding:"1rem", marginBottom:"1rem", color:"white", borderRadius:"5px", alignContent:"center", alignItems:"center",textAlign:"center"}}>
        <img src={hoabl_logo} width="150px" />
        <h2 style={{color:"#FFFFFF", fontFamily:"Jost"}}>Submited!!!</h2>
        <p style={{color:"#FFFFFF", fontFamily:"Jost"}}>Lead Submited Successfully.</p>
      </div>
      <a style={{all:"unset"}} href="https://forms.hoabl.in/lead-demo">
      <button >
        Done
      </button>
      </a>
    </div>
  )
}

export default SubmitLead