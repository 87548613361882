export const choices = {
  profession: [
    {value:"Salaried",id:"370410000"},
    {value:"Self Employed",id:"370410001"},
    {value:"Professional",id:"370410002"},
    {value:"Home Maker",id:"370410003"},
    {value:"Others",id:"370410004"},
  ],
  hoabl_noofchildrenifapplicable: [
    {value:"One", id:1},
    {value:"Two", id:2},
    {value:"Three", id:3},
    {value:"Four", id:4},
    {value:"None", id:0},
  ],
  hoabl_typeofholidaysyouenjoy: [
    {value:"Mountains & Hills", id:"370410000"},
    {value:"Beaches", id:"370410001"},
    {value:"Countryside", id:"370410002"},
    {value:"Off beat destinations", id:"370410003"},
    {value:"City", id:"370410004"},
    {value:"Forest adventures", id:"370410005"},
    {value:"All of the above", id:"370410006"},
    {value:"Do not holiday", id:"370410007"},
  ],
  hoabl_whatareyourlongterminvestmentgoals: [
    {value:"Easy Retirement", id:"370410000"},
    {value:"Children's higher education", id:"370410001"},
    {value:"Children's marriage", id:"370410002"},
    {value:"Others", id:"370410003"},
  ],
  hoabl_whatcaughtyourattentionatthetimeofpurchase:[
    {value:"Brand Name", id:"370410000"},
    {value:"Recommended by friends & family", id:"370410001"},
    {value:"Hoardings", id:"370410002"},
    {value:"The Destination", id:"370410003"},
    // {value:"Pricing", id:"370410004"},
    {value:"Radio Ads", id:"370410005"},
    {value:"Newspaper Ads", id:"370410006"},
  ],
  hoabl_whatisyourfavoritepartofhoabl: [
    {value:"Ease of purchase", id:"370410000"},
    {value:"Amenities & development", id:"370410001"},
    {value:"Trusted Brand Name", id:"370410002"},
    {value:"Location", id:"370410003"},
    {value:"Service", id:"370410004"},
  ],
  hoabl_whatexcitesyoufromthelist: [
    {value:"Concerts & events", id:"370410000"},
    {value:"Movie screenings", id:"370410001"},
    {value:"Sports & Matches", id:"370410002"},
    {value:"Family picnics", id:"370410003"},
    {value:"Day well spent at a spa & salon", id:"370410004"},
    {value:"Shopping", id:"370410005"},
    {value:"Enjoying the bliss of nothing", id:"370410006"},
    {value:"Binge watching", id:"370410007"},
    {value:"Road Trips", id:"370410008"},
    {value:"International Vacations", id:"370410009"},
  ],
  hoabl_whattvchannelsdoyouwatch: [
    {value:"Sony TV", id:"370410000"},
    {value:"Colours", id:"370410001"},
    {value:"Star World", id:"370410002"},
    {value:"Hindi News Channels", id:"370410003"},
    {value:"English New Channels", id:"370410004"},
    {value:"Star Plus", id:"370410005"},
    {value:"Zee TV", id:"370410006"},
    {value:"I don't watch traditional TV channels", id:"370410007"},
  ],
  hoabl_wheredoyouwatchshowsmovieslistentomusic: [
    {value:"Netflix", id:"370410000"},
    {value:"Hotstar", id:"370410001"},
    {value:"Sony Liv", id:"370410002"},
    {value:"Amazon Prime", id:"370410003"},
    {value:"Spotify", id:"370410004"},
    {value:"YouTube", id:"370410005"},
    {value:"Others", id:"370410006"},
  ],
  hoabl_whichnewspaperdoyouread: [
    {value:"Times of India", id:"370410000"},
    {value:"Hindustan Times", id:"370410001"},
    {value:"Maharashtra Times", id:"370410002"},
    {value:"Navbharat Times", id:"370410003"},
    {value:"Gujarat Samachar", id:"370410004"},
    {value:"Others", id:"370410005"},
    {value:"I don't have a newspaper subscription", id:"370410006"},
  ],
  hoabl_purposeofinvestmentwithhoabl: [
    {value:"Long term investment", id:"370410000"},
    {value:"Short term investment", id:"370410001"},
    {value:"Vacation Home", id:"370410002"},
    {value:"Asset for future generations", id:"370410003"},
    {value:"Permanent Home", id:"370410004"},
    {value:"Rental Income", id:"370410005"},
  ],
  hoabl_postpossessionplans: [
    {value:"Resale", id:"370410000"},
    {value:"Rental Income", id:"370410001"},
    {value:"Build a vacation home/commercial space", id:"370410002"},
    {value:"Pass on as inheritance", id:"370410003"},
    {value:"Hold for appreciation", id:"370410004"},
  ],
  hoabl_howdoyouspendyourtimeoff: [
    {value:"At Home", id:"370410000"},
    {value:"Weekend breaks", id:"370410001"},
    {value:"Catching up with friends", id:"370410002"},
    {value:"Family time", id:"370410003"},
    {value:"Still working", id:"370410004"},
    {value:"Fulfilling personal commitments", id:"370410005"},
  ],
  hoabl_favoritesport: [
    {value:"Cricket", id:"370410000"},
    {value:"Football", id:"370410001"},
    {value:"Hockey", id:"370410002"},
    {value:"Tennis", id:"370410003"},
    {value:"Golf", id:"370410004"},
    {value:"Not interested in sports", id:"370410005"},
    {value:"Others", id:"370410006"},
  ],
  relation: [
    {value:"Spouse", id:"1"},
    {value:"Sibling", id:"2"},
    {value:"Child", id:"3"},
    {value:"Mother", id:"4"},
    {value:"Father", id:"5"},
    {value:"Friend", id:"6"},
    {value:"Relative", id:"7"},
  ],
  hoabl_whatdidyoulikethemost: [
    {value:"Virtual Buying", id:"1"},
    {value:"Ease of purchase",id:"2"},
    {value:"Remote assistance",id:"3"},
    {value:"Payment plan",id:"4"},
    {value:"Others",id:"5"}
  ],
  title: [
    {value:"Mr.", id:"1"},
    {value:"Ms.", id:"2"},
    {value:"Mrs.", id:"3"},
    {value:"Adv.", id:"4"},
    {value:"C.A.", id:"5"},
    {value:"Dr.", id:"6"},
    {value:"Ar.", id:"7"},
    {value:"M/S", id:"8"},
  ],
  hoabl_whatareyourholidayplanslike: [
    {value:"International Vacations", id:"1"},
    {value:"Friends Reunion", id:"2"},
    {value:"Family Picnics", id:"3"},
    {value:"Staycations", id:"4"},
    {value:"Road Trips", id:"5"},
    {value:"Shopping", id:"6"},
    {value:"Dining Out", id:"7"},
    {value:"Relaxing at Home", id:"8"},
  ],
  hoabl_whatareyourinterests: [
    {value:"Sports", id:"1"},
    {value:"Music Concerts", id:"2"},
    {value:"Movies", id:"3"},
    {value:"Spa Therapy", id:"4"},
    {value:"Clubbing", id:"5"},
    {value:"Cooking", id:"6"},
    // {value:"Dining Out", id:"7"},
    {value:"Art", id:"8"},
    {value:"Travelling", id:"9"},
    {value:"Adventure Sports", id:"10"},
    {value:"Learning something new", id:"11"},
  ],
  hoabl_whatisyourpreferredformofentertainmentandi: [
    {value:"News", id:"1"},
    {value:"Daily Soaps", id:"2"},
    {value:"Lifestyle", id:"3"},
    {value:"Sports", id:"4"},
    {value:"Wildlife", id:"5"},
    {value:"Travel", id:"6"},
    {value:"Fashion", id:"7"},
    {value:"Reality Shows", id:"8"},
    {value:"Comedy", id:"9"},
    {value:"Live Events", id:"10"},
    {value:"OTT Apps", id:"11"},
  ],
  hoabl_whichisyourpreferredsocialmediachannel: [
    {value:"Facebook", id:"1"},
    {value:"Instagram", id:"2"},
    {value:"WhatsApp", id:"3"},
    {value:"Twitter", id:"4"},
    {value:"YouTube", id:"5"},
    {value:"Snapchat", id:"6"},
    {value:"Telegram", id:"7"},
    {value:"None", id:"8"},
  ],
  states: [
    {
    code:"AN",
    value:"Andaman and Nicobar Islands"
    },
    {
    code:"AP",
    value:"Andhra Pradesh"
    },
    {
    code:"AR",
    value:"Arunachal Pradesh"
    },
    {
    code:"AS",
    value:"Assam"
    },
    {
    code:"BR",
    value:"Bihar"
    },
    {
    code:"CG",
    value:"Chandigarh"
    },
    {
    code:"CH",
    value:"Chhattisgarh"
    },
    {
    code:"DH",
    value:"Dadra and Nagar Haveli"
    },
    {
    code:"DD",
    value:"Daman and Diu"
    },
    {
    code:"DL",
    value:"Delhi"
    },
    {
    code:"GA",
    value:"Goa"
    },
    {
    code:"GJ",
    value:"Gujarat"
    },
    {
    code:"HR",
    value:"Haryana"
    },
    {
    code:"HP",
    value:"Himachal Pradesh"
    },
    {
    code:"JK",
    value:"Jammu and Kashmir"
    },
    {
    code:"JH",
    value:"Jharkhand"
    },
    {
    code:"KA",
    value:"Karnataka"
    },
    {
    code:"KL",
    value:"Kerala"
    },
    {
      code:"LA",
      value:"Ladakh"
    },
    {
    code:"LD",
    value:"Lakshadweep"
    },
    {
    code:"MP",
    value:"Madhya Pradesh"
    },
    {
    code:"MH",
    value:"Maharashtra"
    },
    {
    code:"MN",
    value:"Manipur"
    },
    {
    code:"ML",
    value:"Meghalaya"
    },
    {
    code:"MZ",
    value:"Mizoram"
    },
    {
    code:"NL",
    value:"Nagaland"
    },
    {
    code:"OR",
    value:"Odisha"
    },
    {
    code:"PY",
    value:"Puducherry"
    },
    {
    code:"PB",
    value:"Punjab"
    },
    {
    code:"RJ",
    value:"Rajasthan"
    },
    {
    code:"SK",
    value:"Sikkim"
    },
    {
    code:"TN",
    value:"Tamil Nadu"
    },
    {
    code:"TS",
    value:"Telangana"
    },
    {
    code:"TR",
    value:"Tripura"
    },
    {
    code:"UK",
    value:"Uttarakhand"
    },
    {
    code:"UP",
    value:"Uttar Pradesh"
    },
    {
    code:"WB",
    value:"West Bengal"
    }
  ]
}










